require('datatables.net-bs4')
import { trimChars, readableStatus, toMoney, getDom, getLanguage, delayed, getParam, setParam} from 'packs/helper';
import { removeListing, updateListingStrategy, getListingsStrategyToolTip, getDatatableStrategiesHtml, getImageDiv, listingSourceStatusBadge } from 'packs/shared/listings';

$(function() {
    setupAmazonListingsFilters();
    setupAmazonListingsTable();
    setupAmazonListingsListeners();
});

function setupAmazonListingsFilters() {
    $("#amazonListingsSearch").val(getParam(window.location.href, "search"))
    $("#amazonListingsStatusFilter").val(getParam(window.location.href, "status"))
    $("#amazonListingsChannelFilter").val(getParam(window.location.href, "channel") || "fbm")
}

function setupAmazonListingsListeners() {

    $('#amazonListingsSearch').on("input", delayed(function (e) {
        setParam("search", $(this).val());
        $('#amazonListings').DataTable().ajax.reload();
    }, 500));

    $('#amazonListingsStatusFilter').on("input", function (e) {
        setParam("status", $(this).val());
        $('#amazonListings').DataTable().ajax.reload();
    });

    $('#amazonListings').on("change", ".inline-strategy-select", function (e) {
        var strategyId = $(this).val();
        var listingId = $(this).data('id');
        updateListingStrategy(listingId, strategyId)
    });

    $('#amazonListingsChannelFilter').on("input", function (e) {
        setParam("channel", $(this).val());
        $('#amazonListings').DataTable().ajax.reload();
    });

    $(document).on('click', '.delete-listing-btn', function (e) {
        Swal.fire({
            title: 'Are you sure?',
            text: "Your listing will be deleted from Amazon",
            showCancelButton: true,
            confirmButtonColor: 'var(--danger)',
            cancelButtonColor: 'var(--secondary)',
            confirmButtonText: 'Delete',
            showClass: {
                popup: '',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                var listingId = $(this).data("id");
                removeListing(listingId);
            }
        })
    })
}

function setupAmazonListingsTable() {
    $('#amazonListings').DataTable({
        dom: getDom(),
        rowId: 'id',
        sScrollX: true,
        oLanguage: getLanguage(),
        processing: true,
        serverSide: true,
        fixedHeader: {
            headerOffset: 68
        },
        pagingType: "numbers",
        ajax: {
            url: "/listings/fetch",
            type: "GET",
            data: function(d){
                d.search_phrase = $("#amazonListingsSearch").val()
                d.status = $("#amazonListingsStatusFilter").val()
                d.channel = $("#amazonListingsChannelFilter").val()
            },
        },
        bLengthChange: false,
        bFilter: false,
        paging: true,
        pageLength: 25,
        ordering: true,
        order: [[ 7, "desc" ]],
        columns: [
            {
                title: "id",
                data: "id",
                name: "id",
                visible: false
            },
            {
                title: "Amazon",
                orderable: false,
                render: function(data, type, row){
                    var html =  `<div class="d-flex" style="align-items: center;"> 
                                    ${getImageDiv(row.image_url)}
                                    <div class="float-left">
                                        <a target="_blank" href="https://amazon.com/dp/${row.sku}" class="align-self-center mb-0">${trimChars(row.name || '--', 40)} <svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                                        <p class="align-self-center mb-0">${row.store_sku} (${row.sku})</p>
                                        <p class="align-self-center mb-0">${makeAmazonBadge(readableStatus(row.status), row.in_stock, row.error_message)}</p>
                                        <p class="align-self-center mb-0">${toMoney(row.price) || "--.--"}</p>
                                    </div>
                                </div>`

                    return html
                }
            },
            {
                title: "Supplier",
                orderable: false,
                render: function(data, type, row){
                    if (!row.is_fba) {
                        return `<div class="d-flex" style="align-items: center;"> 
                                    ${getImageDiv(row.source_image_url)}
                                    <div class="float-left">
                                        <a target="_blank" href="${row.source_url}" class="align-self-center mb-0">${trimChars(row.source_name || '--', 40)} <svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                                        <p class="align-self-center mb-0">${row.source_id || "--"}</p>
                                        <p class="align-self-center mb-0">${listingSourceStatusBadge(row.source_in_stock, row.is_valid)}</p>
                                        <p class="align-self-center mb-0">${toMoney(row.cost) || "--.--"}</p>
                                    </div>
                                </div>`
                    } else {
                        return '<span>Fulfilled By Amazon</span>'
                    }
                }
            },
            {
                title: "Profit",
                class: "text-right",
                data: "estimated_profit",
                name: "estimated_profit",
                orderable: true,
                render: function(data, type, row) {
                    return `<span class="bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="${makeProfitToolTipAmazon(row.price, row.cost, row.shipping, row.estimated_tax, row.estimated_fees, row.pick_and_pack_fee, row.is_fba)}">${toMoney(data)}</span>`
                }
            },
            {
                title: "Handling",
                orderable: false,
                class: "text-right",
                data: "lag_time"
            },
            {
                title: "Repricer",
                class: "text-center",
                orderable: false,
                render: function(data, type, row) {
                    var html = `<select data-id="${row.id}" class="form-control mx-auto inline-strategy-select bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="${getListingsStrategyToolTip(row.min_price, row.max_price)}" name="status" style="min-width: 170px; color:#1a1f36;">
                                    ${getDatatableStrategiesHtml(row.repricer_strategy_id)}
                                </select>`
                    if (row.disable_repricer) html = '<span class="badge badge-secondary">Disabled</span>';
                    if ($("#disable_repricer").val() == 'true') html = '<span class="badge badge-secondary">Disabled</span>';
                    
                    return html
                }
            },
            {
                title: "Stock Monitor",
                class: "text-center",
                orderable: false,
                render: function(data, type, row) {
                    var enabled = true
                    if (row.disable_stock_monitoring) enabled = false;
                    if ($("#disable_stock_monitoring").val() == 'true') enabled = false;

                    if (enabled) {
                        return '<span class="badge badge-success">Enabled</span>';
                    } else {
                        return '<span class="badge badge-secondary">Disabled</span>';
                    }
                }
            },
            {
                title: "Created",
                class: "text-center",
                name: "created_at",
                orderable: true,
                render: function(data, type, row) {
                    var html = `<div class='text-center'>
                                <span>${row.created_by_name}</span><br>
                                <span class="text-secondary fs-12">${row.created_at_in_words} ago</span>
                            </div>`

                    return html;
                }
            },
            {
                title: "",
                width: "30px",
                orderable: false,
                render: function(data, type, row){
                    if (!row.is_fba) {
                        return `<button data-id="${row.id}" data-toggle="modal" data-target="#viewListingHistory" class="btn btn-icon listing-history">
                                    <svg class="action-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="History" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                </button>
                                <a local="false" data-toggle="modal" data-target="#editAmazonListingModal" class="btn btn-icon" data-remote="true" href="/amazon_listings/edit?id=${row.id}">
                                    <svg class="action-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Edit" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                </a>
                                <svg class="action-btn action-btn-delete delete-listing-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Delete" data-id="${row.id}" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>`
                    } else {
                        return `<a local="false" data-toggle="modal" data-target="#editAmazonListingModal" class="btn btn-icon" data-remote="true" href="/amazon_listings/edit?id=${row.id}">
                                    <svg class="action-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Edit" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                </a>`
                    }
                }
            }
        ],
    });
}

function makeAmazonBadge(status, in_stock, error_message) {
    if (status == 'Active') {
        if (in_stock) {
            return '<span class="badge badge-success">In Stock</span>';
        } else {
            return '<span class="badge badge-warning">Out Of Stock</span>';
        }
    } else if (status == 'Pending Removal') {
        return '<span class="badge badge-warning">' + status + '</span>';
    } else if (status == 'Error' && error_message && error_message != "") {
        return '<span class="badge badge-dark bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="' + error_message + '">' + status + '</span>';
    } else if (status == "Pending Upload") {
        return '<span class="badge badge-dark" bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Your item will be submitted to Amazon soon">' + status + '</span>';
    } else if (status == "Submitted") {
        return '<span class="badge badge-dark" bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Your item was submitted to Amazon, it will be active shortly">' + status + '</span>';
    } else {
        return '<span class="badge badge-dark">' + status + '</span>';
    }
}

function makeProfitToolTipAmazon(revenue, cost, shipping, tax, fees, pickAndPackFees, isFba) {
    var fbaHtml = '';
    if (isFba) fbaHtml = `<span>Pick & Pack: ${toMoney(pickAndPackFees, 2)}</span><br>`;

    var html = `<div class='text-right'>
        <span>Revenue: ${toMoney(revenue)}</span><br>
        <span>Cost: ${toMoney((cost || 0), 2)}</span><br>
        <span>Shipping: ${toMoney((shipping || 0), 2)}</span><br>
        <span>Tax: ${toMoney((tax || 0), 2)}</span><br>
        <span>Fees: ${toMoney(fees, 2)}</span><br>
        ${fbaHtml}
    </div>`

return html;
}