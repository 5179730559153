require('datatables.net-bs4')
import { trimChars, ordersGetDateRange, readableStatus, fetchAmazonOrdersAnalytics, toMoney, flashError, getDom, getBadgeColor, getLanguage, delayed, getParam, setParam, getFormattedDate } from 'packs/helper';
import { getSkuElement, getSourcesElement, getOrderTableEditButton, getSupplierOrderIdSpan, getOrderSupplierOrderIdIcon } from 'packs/shared/orders';

$(function() {
    setupAmazonOrdersFilters();
    setupAmazonOrdersTable();
    fetchAmazonOrdersAnalytics();
    setupAmazonOrdersTableListeners();
});

function setupAmazonOrdersFilters() {
    var today = new Date()
    var startOfMonth = new Date()
    var thirtyDaysAgo = new Date()
    var sevenDaysAgo = new Date()
    var baseLastMonth = new Date()
    var lastMonthEnd = baseLastMonth.setDate(0)
    var lastMonthStart = baseLastMonth.setDate(1)
    startOfMonth.setDate(1)
    thirtyDaysAgo.setDate(today.getDate() - 30)
    sevenDaysAgo.setDate(today.getDate() - 7)

    $('#amazonOrderDateRangePicker span').html(getFormattedDate(thirtyDaysAgo) + ' - ' + getFormattedDate(today));

    $('#amazonOrderDateRangePicker').daterangepicker({
        startDate: thirtyDaysAgo,
        endDate: today,
        ranges: {
            'Lifetime': [new Date(null), today],
            'Last 7 Days': [sevenDaysAgo, today],
            'Last 30 Days': [thirtyDaysAgo, today],
            'This Month': [startOfMonth, today],
            'Last Month': [lastMonthStart, lastMonthEnd],
        }
    });

    $('#amazonOrderDateRangePicker').on('apply.daterangepicker', function(ev, picker) {
        $('#amazonOrderDateRangePicker span').html(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        $('#amazonOrders').DataTable().ajax.reload();
        fetchAmazonOrdersAnalytics();
    });

    $("#amazonOrdersSearch").val(getParam(window.location.href, "search"))
    $("#amazonOrdersStatusFilter").val(getParam(window.location.href, "status"))
    $("#amazonOrdersChannelFilter").val(getParam(window.location.href, "channel") || 'fbm')

    if (getParam(window.location.href, "late") == 'true') {
        $("#amazonOrdersToggleLate").addClass('active');
    } else {
        $("#amazonOrdersToggleLate").removeClass('active');
    }

    if (getParam(window.location.href, "ship_today") == 'true') {
        $("#amazonOrdersToggleShipToday").addClass('active');
    } else {
        $("#amazonOrdersToggleShipToday").removeClass('active');
    }
}

function setupAmazonOrdersTableListeners() {
    $('#amazonOrders').on("click", '.status-change-item',function (e) {
        var newStatus = $(this).text().toLowerCase();
        var ids = $(this).parent().data('id');
        var sourceOrderNumber = $(this).parent().data('son');
        var currentStatus = $(this).parent().data('status');
        var sourceCost = $(this).parent().data('sourceCost');
        var row = $('#amazonOrders').DataTable().row($(this).parents('tr'));
        newStatus = newStatus.replaceAll(" ", "_");

        if (currentStatus == 'new' && newStatus == 'ordered' && !sourceOrderNumber) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You are setting an order to "Ordered" without a supplier order id or subtotal. The current cost will be saved if you continue.',
                showCancelButton: true,
                confirmButtonColor: 'var(--success)',
                cancelButtonColor: 'var(--secondary)',
                confirmButtonText: "Confirm",
                showClass: {
                    popup: '',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    updateAmazonOrderItemStatus(newStatus, ids, row, this, sourceCost)
                }
            })
        } else {
          updateAmazonOrderItemStatus(newStatus, ids, row, this)
        }   
    });

    $(document).on("click", '#orderNumberSave',function (e) {
        updateAmazonOrderNumber(this);
    });

    $(document).on("click", '#notesSave',function (e) {
        updateNotes(this);
    });

    $('#amazonOrdersSearch').on("input", delayed(function (e) {
        setParam("search", $(this).val());
        $('#amazonOrders').DataTable().ajax.reload();
        fetchAmazonOrdersAnalytics();
    }, 500));

    $('#amazonOrdersStatusFilter').on("input", function (e) {
        setParam("status", $(this).val());
        $('#amazonOrders').DataTable().ajax.reload();
        fetchAmazonOrdersAnalytics();
    });

    $('#amazonOrdersChannelFilter').on("input", function (e) {
        setParam("channel", $(this).val());
        $('#amazonOrders').DataTable().ajax.reload();
        fetchAmazonOrdersAnalytics();
    });

    // Listener for the extension, when the extension modifies this input
    // We need to update this orders data and modal inputs if its open
    $('#orderModifiedInput').on("change", function (e) {
        var orderData = JSON.parse($(this).val());
        var row = $('#amazonOrders').DataTable().row($("#order-row-" + orderData.orderItemId).closest('tr'));
        var order = row.data()

        // Update the row data
        order.source_cost = orderData.cost;
        order.source_tax = orderData.tax;
        order.source_shipping = orderData.shipping;
        order.source_order_number = orderData.source_order_number;

        if (order.status == 'new' && order.source_order_number) {
            console.log('setting status to ordered')
            order.status = 'ordered';
        }
        
        row.data(order);
        //  Update the modal inputs
        $("#editAmazonOrderNumber").val(orderData.source_order_number)
        $("#editAmazonShipping").val(orderData.shipping)
        $("#editAmazonSourceTax").val(orderData.tax)
        $("#editAmazonItemCost").val(orderData.cost).trigger("input");
    });

    $(document).on("click", '.edit-order-btn', function (e) {
        $("#amazonOrderItemEditModal").html("");
    });

    $("#amazonOrdersToggleLate").on("click", function (e) {
        var on = $(this).hasClass('active');
        setParam("late", !on);

        if (on) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
        $('#amazonOrders').DataTable().ajax.reload();
        fetchAmazonOrdersAnalytics();
    });

    $("#amazonOrdersToggleShipToday").on("click", function (e) {
        var on = $(this).hasClass('active');
        setParam("ship_today", !on);

        if (on) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
        $('#amazonOrders').DataTable().ajax.reload();
        fetchAmazonOrdersAnalytics();
    });

    $(document).on("click", '.edit-order-btn', function (e) {
        $("#amazonOrderItemEditModal").html("");
    });

    $(document).on("click", '.add-tracking-btn', function (e) {
        $("#amazonOrderItemShipmentsModal").html("");
    });
}

function updateAmazonOrderNumber(button) {
    var order_item_ids = $("#amazonOrderNumberOrderItemIds").val();
    var source_order_number = $("#editSourceOrderNumber").val();
    $.ajax({
        url: "/amazon_order_items/update",
        type: "PATCH",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            order_item_ids: order_item_ids,
            source_order_number: source_order_number
        },
        success: function(resp) {
            var updatedOrder = resp.order[0]
            var row = $('#amazonOrders').DataTable().row($("#order-row-" + updatedOrder.order_item_id).closest('tr'));
            row.data(updatedOrder)
            
            $(button).prop('disabled', false);
            $("#amazonOrderNumberModal").modal("hide");
        }
    });
    
}

function updateAmazonOrderItemStatus(status, ids, row, button, sourceCost = null) {
    var updateObj = { order_item_ids: ids, status: status }
    if (sourceCost) updateObj.source_cost = sourceCost

    $.ajax({
        url: "/amazon_order_items/update",
        type: "PATCH",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: updateObj,
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
            } else {
                var rowData = row.data();
                rowData.status = status
                row.data(rowData).draw();
            }
            $(button).prop('disabled', false);
        }
    });
}

function setupAmazonOrdersTable() {
    $('#amazonOrders').DataTable({
        dom: getDom(),
        oLanguage: getLanguage(),
        processing: true,
        serverSide: true,
        pagingType: "numbers",
        sScrollX: true,
        ajax: {
            url: "/orders/fetch_amazon_orders",
            type: "GET",
            data: function(d){
                d.search_phrase = $("#amazonOrdersSearch").val()
                d.status = $("#amazonOrdersStatusFilter").val()
                d.channel = $("#amazonOrdersChannelFilter").val()
                d.late = $("#amazonOrdersToggleLate").hasClass("active")
                d.ship_today = $("#amazonOrdersToggleShipToday").hasClass("active")
                d.date_range = [$('#amazonOrderDateRangePicker').data('daterangepicker').startDate.format('YYYY-MM-DD'), $('#amazonOrderDateRangePicker').data('daterangepicker').endDate.format('YYYY-MM-DD')]
            },
        },
        bLengthChange: false,
        paging: true,
        bFilter: false,
        pageLength: 50,
        ordering: false,
        createdRow: function(row) {
            $(row).addClass('no-hover-bg');
        },
        columns: [
            {
                title: "Order",
                class: "pb-0 px-0",
                render: function(data, type, row){
                    return getAmazonOrdersTableRowHtml(row);
                }
            },
        ],
    });

    $("#amazonOrders_wrapper .dt--top-section").css({"display": "none"});
    $("#amazonOrders_wrapper .dt--bottom-section").addClass("px-0");
    $("#amazonOrders_wrapper .dt--bottom-section").addClass("pb-0");
    $('#amazonOrders_wrapper .table-responsive').css({'min-height': '250px'});
}

function getAmazonOrdersTableRowHtml(row) {
    var amazonUrl = `https://www.amazon.com/dp/${row.asin}`

    var html = `<div class="widget-content widget-content-area no-shadow animated-underline-content" id="order-row-${row.order_item_id}">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-8">                                    
                                    <div class="w-135" style="display:inline-block;">
                                        <div class="btn-group mr-2" role="group">
                                            <button id="statusChange" type="button" class="btn btn-sm btn-${getBadgeColor(row.status)} dropdown-toggle" style="height: 32.5px;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">${readableStatus(row.status)} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></button>
                                            <div class="dropdown-menu" data-source-cost="${row.current_source_price * row.quantity}" data-son="${row.source_order_number}" data-status="${row.status}" data-id="${row.order_item_id}" aria-labelledby="statusChange" style="will-change: transform;">
                                                <a href="javascript:void(0);" class="dropdown-item status-change-item"><i class="flaticon-dots mr-1"></i>New</a>
                                                <a href="javascript:void(0);" class="dropdown-item status-change-item"><i class="flaticon-home-fill-1 mr-1"></i>Ordered</a>
                                                <a href="javascript:void(0);" class="dropdown-item status-change-item"><i class="flaticon-home-fill-1 mr-1"></i>Shipped</a>
                                                <a href="javascript:void(0);" class="dropdown-item status-change-item"><i class="flaticon-dots mr-1"></i>Out of Stock</a>
                                                <a href="javascript:void(0);" class="dropdown-item status-change-item"><i class="flaticon-gear-fill mr-1"></i>Cancelled</a>
                                                <a href="javascript:void(0);" class="dropdown-item status-change-item"><i class="flaticon-bell-fill-2 mr-1"></i>Request Return</a>
                                                <a href="javascript:void(0);" class="dropdown-item status-change-item"><i class="flaticon-bell-fill-2 mr-1"></i>Refunded</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:calc(100% - 130px);display:inline-block;">
                                        <span class="mr-4">${row.order_date_f}</span>
                                        <a target="_blank" href="https://sellercentral.amazon.com/orders-v3/order/${row.source_order_id}" class="copyable bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Copy Id and go to Amazon (new tab)">${row.source_order_id}</a>
                                        <svg data-text="${row.source_order_id}" class="action-btn action-btn-sm mr-4 copy-data-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Copy Order Id" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                                        <svg data-text="${row.source_order_id}" class="action-btn action-btn-sm mr-4 copy-btn-success bs-tooltip" style="display:none;" data-placement="top" data-html="true" data-toggle="tooltip" title="Copy Order Id" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                                        ${getSupplierOrderIdHtml(row.order_item_id, row.source_order_number, row.is_fba)}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="float-right">
                                        ${getOrderTableEditButton(row.status, row.order_item_id, row.source_url, 'amazon', `https://sellercentral.amazon.com/orders-v3/order/${row.source_order_id}`, row.is_fba)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr style="margin-left: -20px; margin-right: -20px;">
                            <div class="col-12 widget-content" style="overflow: auto; margin-top: 20px;">
                                <div class="row">
                                    <div class="col-lg-8 col-md-8 col-sm-12 pl-0">
                                        <div class="float-left table-img mr-2 br-5">
                                            <img alt="" class="img-fluid" src="${row.image_url}">
                                        </div>
                                        <div class="float-left">
                                            <div class="">
                                                <a class="text-dark" target="_blank" href="${amazonUrl}"><span>${trimChars(row.name, 80) || 'Missing Listing <svg class="action-btn action-btn-sm mr-0 action-btn-delete" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>'}</span></a>
                                            </div>

                                            <div class="">
                                                ${getSkuElement(row.asin, 'amazon')}
                                                ${getSourcesElement(row.source_id, row.source_url, 'walmart', row.is_fba)}
                                                <span class="mr-3"><span class="text-muted">Quantity: </span>${row.quantity}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-12">
                                        <div class="row">
                                            <div class="col-8">
                                                ${getTrackingAndShipTimeDiv(row.order_item_id, row.tracking_numbers, row.latest_ship_date_f, row.estimated_ship_date_in_words, row.is_fba)}
                                            </div>
                                            <div class="col-4 pr-0">
                                                <div class="float-right text-right">
                                                    <div class="col-12 pr-0">
                                                        <span><span class="text-muted">Gross Sales:</span> ${toMoney(row.total)}</span>
                                                    </div>
                                                    <div class="col-12 pr-0">
                                                        <span><span class="text-muted">Profit:</span><span class="bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="${getOrdersProfitToolTipAmazon(row.total, row.tax, row.shipping, row.marketplace_fees, row.net_payable, (row.source_cost || (row.current_source_price * row.quantity)), row.is_estimate, row.profit, row.margin, row.is_fba, row.pick_and_pack)}"> ${toMoney(row.profit)}</span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`
    return html;
}

function getTrackingAndShipTimeDiv(order_item_id, tracking_numbers, latest_ship_date_f, estimated_ship_date_in_words, is_fba) {
    if (!is_fba) {
        return `<div class="float-left text-left">
                    <div class="col-12">
                        <span><span class="text-muted">Tracking:
                        <a local="false" data-toggle="modal" data-target="#amazonOrderItemShipmentsModal" class="btn btn-icon add-tracking-btn" data-remote="true" href="/amazon_order_item/shipments?order_item_id=${order_item_id}">
                            <svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        </a>
                        </span>${tracking_numbers || ""}</span>
                    </div>
                    <div class="col-12">
                        <span><span class="text-muted">Latest Ship: </span><span class="bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="${latest_ship_date_f}">${estimated_ship_date_in_words}</span></span>
                    </div>
                </div>`
    } else {
        return '';
    }
}

function getSupplierOrderIdHtml(order_item_id, source_order_number, is_fba) {
    if (!is_fba) {
        return `<span><span class="text-muted">Supplier Order ID:
                ${getOrderSupplierOrderIdIcon(order_item_id, source_order_number, 'amazon')}
            </span"><span class="text-dark" id="sn-${order_item_id}">${getSupplierOrderIdSpan(source_order_number)}</span></span>`
    } else {
        return '';
    }
}

function getOrdersProfitToolTipAmazon(total, tax, shipping, marketplace_fees, net_payable, source_cost, is_estimate, profit, margin, isFba, pickPackFees) {
    var estimateStr = '';
    var ppFeeHtml = '';
    if (is_estimate && !isFba) estimateStr = ' (est)';
    if (isFba) ppFeeHtml = `<span>Pick & Pack: ${toMoney(pickPackFees || 0)}</span><br>`;

    var html = `<div class='text-right'>
                    <span>Gross Sales: ${toMoney(total)}</span><br>
                    <span>Shipping: ${toMoney(shipping || 0)}</span><br>
                    <span>Tax: ${toMoney(tax || 0)}</span><br>
                    <span>Marketplace Fees: ${toMoney(marketplace_fees)}</span><br>
                    ${ppFeeHtml}
                    <span>Net Payable: ${toMoney(net_payable || 0)}</span><br>
                    <span>Supplier Total${estimateStr}: ${toMoney(source_cost)}</span><br>
                    <span>Profit${estimateStr}: ${toMoney(profit)}</span><br>
                    <span>Margin${estimateStr}: ${margin}%</span><br>                    
                </div>`

    return html;
}