require('datatables.net-bs4')

import { getDom, getLanguage, flashError, flashSuccess, readableStatus } from 'packs/helper';

$(function() {
  setupOgranizationsUsersTable();
  setupOrganizationUsersListeners();
});

function setupOrganizationUsersListeners() {
  $(document).on('ajax:success', '.new-org-user-form', function(e, data, status, xhr) {
      $("#addOrganizationUserModal").modal("hide");
      $('.modal-backdrop').remove();
      $('#organizationsUsers').DataTable().ajax.reload();
  }).on('ajax:error', '.new-org-user-form', function(e){
      var detail = e.detail;
      var error = detail[0];
      flashError(error["errors"]);
  });
  
  $('#addOrganizationUserModal').on('hidden.bs.modal', function (e) {
    $(this)
      .find("input")
         .val('')
         .end()
  });
}

function setupOgranizationsUsersTable() {
  $('#organizationsUsers').DataTable({
      dom: getDom(),
      oLanguage: getLanguage(),
      processing: true,
      serverSide: true,
      pagingType: "numbers",
      ajax: {
          url: "/organization/users",
          type: "GET"
      },
      bFilter: false,
      bLengthChange: false,
      paging: true,
      pageLength: 20,
      ordering: false,
      columns: [
          {
            title: "First Name",
            data: "first_name"
          },
          {
            title: "Last Name",
            data: "last_name"
          },
          {
            title: "Email",
            data: "email"
          },
          {
            title: "Role",
            data: "role",
            render: function (data, type, row) {
              return data.substr(0,1).toUpperCase()+data.substr(1);
            }
          },
          {
            title: "Added",
            data: "added_date"
          },
          {
              title: "",
              width: "30px",
              render: function(data, type, row){
                  var html = '';
                  if (row.role != 'owner') {
                    html = `<button class="btn btn-icon delete-btn" data-table="organizationsUsers" data-path="/organization/remove_user/" data-id="${row.id}"><svg class="action-btn" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button>`
                  }

                  return html;
              }
          }
      ],
  });
}