require('datatables.net-bs4')
import { trimChars, readableStatus, toMoney, getDom, getLanguage, delayed, flashSuccess, flashError, getParam, setParam} from 'packs/helper';
import { removeListing, updateListingStrategy, getListingsProfitToolTip, getListingsStrategyToolTip, getDatatableStrategiesHtml, getImageDiv, listingSourceStatusBadge } from 'packs/shared/listings';

$(function() {
    setupListingsFilters();
    setupListingsTable();
    setupListingsListeners();
});

function setupListingsFilters() {
    $("#listingsSearch").val(getParam(window.location.href, "search"))
    $("#listingsStatusFilter").val(getParam(window.location.href, "status"))
}

function setupListingsListeners() {
    $('#listingsSearch').on("input", delayed(function (e) {
        setParam("search", $(this).val());
        $('#listings').DataTable().ajax.reload();
    }, 500));

    $('#listingsStatusFilter').on("input", function (e) {
        setParam("status", $(this).val());
        $('#listings').DataTable().ajax.reload();
    });

    $('#listings').on("change", ".inline-strategy-select", function (e) {
        var strategyId = $(this).val();
        var listingId = $(this).data('id');
        updateListingStrategy(listingId, strategyId)
    });

    $(document).on('ajax:success', '.upload-listings-form', function(e, data, status, xhr) {
        flashSuccess('Your products are being uploaded, they will be available soon')
        $("#importListingsModal").modal("hide");
        $("#importFileField").val('');
    }).on('ajax:error', '.upload-listings-form', function(e){
        var detail = e.detail;
        var error = detail[0];
        flashError(error["errors"]);
    });

    $('#listingsStatusFilter').on("input", function (e) {
        setParam("status", $(this).val());
        $('#listings').DataTable().ajax.reload();
    });

    $(document).on('click', '.delete-listing-btn', function (e) {
        Swal.fire({
            title: 'Are you sure?',
            text: "Your listing will be deleted from Walmart",
            showCancelButton: true,
            confirmButtonColor: 'var(--danger)',
            cancelButtonColor: 'var(--secondary)',
            confirmButtonText: 'Delete',
            showClass: {
                popup: '',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                var listingId = $(this).data("id");
                removeListing(listingId);
            }
        })
    })
}

function setupListingsTable() {
    $('#listings').DataTable({
        dom: getDom(),
        rowId: 'id',
        sScrollX: true,
        oLanguage: getLanguage(),
        processing: true,
        serverSide: true,
        pagingType: "numbers",
        fixedHeader: {
            headerOffset: 68
        },
        ajax: {
            url: "/listings/fetch",
            type: "GET",
            data: function(d){
                d.search_phrase = $("#listingsSearch").val()
                d.status = $("#listingsStatusFilter").val()
            },
        },
        bLengthChange: false,
        bFilter: false,
        paging: true,
        pageLength: 25,
        ordering: true,
        order: [[ 7, "asc" ]],
        fnServerParams: function(data) {
            data['order'].forEach(function(items, index) {
                data['order'][index]['column_name'] = data['columns'][items.column]['data'];
          });
        },
        columns: [
            {
                title: "id",
                data: "id",
                name: "id",
                visible: false
            },
            {
                title: "Walmart",
                orderable: false,
                render: function(data, type, row){
                    var html =  `<div class="d-flex" style="align-items: center;"> 
                                    ${getImageDiv(row.image_url)}
                                    <div class="float-left">
                                        <a target="_blank" href="https://walmart.com/ip/${row.sku}" class="align-self-center mb-0">${trimChars(row.name || '--', 40)} <svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                                        <p class="align-self-center mb-0">${row.store_sku}</p>
                                        <p class="align-self-center mb-0">${makeWalmartBadge(readableStatus(row.status), row.in_stock)}</p>
                                        <p class="align-self-center mb-0">${toMoney(row.price) || "--.--"}</p>
                                    </div>
                                </div>`

                    return html
                }
            },
            {
                title: "Supplier",
                orderable: false,
                render: function(data, type, row){
                    var html =  `<div class="d-flex" style="align-items: center;"> 
                                    ${getImageDiv(row.source_image_url)}
                                    <div class="float-left">
                                        <a target="_blank" href="${row.source_url}" class="align-self-center mb-0">${trimChars(row.source_name || '--', 40)} <svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                                        <p class="align-self-center mb-0">${row.source_id || "--"}</p>
                                        <p class="align-self-center mb-0">${listingSourceStatusBadge(row.source_in_stock, row.is_valid)}</p>
                                        <p class="align-self-center mb-0">${toMoney(row.cost) || "--.--"}</p>
                                    </div>
                                </div>`

                    return html
                }
            },
            {
                title: "Profit",
                class: "text-right",
                orderable: true,
                name: "estimated_profit",
                data: "estimated_profit",
                render: function(data, type, row) {
                    return `<span class="bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="${getListingsProfitToolTip(row.price, row.cost, row.estimated_tax, row.estimated_fees)}">${toMoney(data)}</span>`
                }
            },
            {
                title: "Repricer",
                class: "text-center",
                render: function(data, type, row) {
                    var html = `<select data-id="${row.id}" class="form-control mx-auto inline-strategy-select bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="${getListingsStrategyToolTip(row.min_price, row.max_price)}" name="status" style="min-width: 170px; color:#1a1f36;">
                                    ${getDatatableStrategiesHtml(row.repricer_strategy_id)}
                                </select>`
                                
                    if (row.disable_repricer) html = '<span class="badge badge-secondary">Disabled</span>';
                    if ($("#disable_repricer").val() == 'true') html = '<span class="badge badge-secondary">Disabled</span>';
                                
                    return html
                }
            },
            {
                title: "Stock Monitor",
                class: "text-center",
                orderable: false,
                render: function(data, type, row) {
                    var enabled = true
                    if (row.disable_stock_monitoring) enabled = false;
                    if ($("#disable_stock_monitoring").val() == 'true') enabled = false;

                    if (enabled) {
                        return '<span class="badge badge-success">Enabled</span>';
                    } else {
                        return '<span class="badge badge-secondary">Disabled</span>';
                    }
                }
            },
            {
                title: "Created",
                class: "text-center",
                name: "created_at",
                data: "created_at",
                orderable: true,
                render: function(data, type, row) {
                    var html = `<div class='text-center'>
                                <span>${row.created_by_name}</span><br>
                                <span class="text-secondary fs-12">${row.created_at_in_words} ago</span>
                            </div>`

                    return html;
                }
            },
            {
                title: "",
                width: "30px",
                render: function(data, type, row){
                    var html = `<a local="false" data-toggle="modal" data-target="#editListingModal" class="btn btn-icon" data-remote="true" href="/walmart_listings/edit?id=${row.id}">
                                    <svg class="action-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Edit" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                </a>
                                <svg class="action-btn action-btn-delete delete-listing-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Delete" data-id="${row.id}" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>`
                    return html;
                }
            }
        ],
    });
}

function makeWalmartBadge(status, in_stock) {
    if (status == 'Active') {
        if (in_stock) {
            return '<span class="badge badge-success">In Stock</span>';
        } else {
            return '<span class="badge badge-secondary">Out Of Stock</span>';
        }
    } else if (status == 'Pending Removal') {
        return '<span class="badge badge-warning">' + status + '</span>';
    } else {
        return '<span class="badge badge-dark">' + status + '</span>';
    }
}