require('datatables.net-bs4')

import { delayed, getDom, getLanguage, flashError, flashSuccess, readableStatus, deleteNode } from 'packs/helper';

$(function() {
  setupRepricerView();
  setupStrategiesTable();
  setupStrategyListeners();
});

function setupRepricerView() {
    $('#repricerStrategySearch').on("input", delayed(function (e) {
      $('#strategies').DataTable().ajax.reload();
    }, 500));

    $(document).on('click', '.set-default-strategy', function (e) {
      Swal.fire({
          title: 'Are you sure?',
          text: "This will be set as your stores default strategy",
          showCancelButton: true,
          confirmButtonColor: 'var(--success)',
          cancelButtonColor: 'var(--secondary)',
          confirmButtonText: 'Confirm',
          showClass: {
              popup: '',
          },
      }).then((result) => {
          if (result.isConfirmed) {
            var strategyId = $(this).data("id");
            setNewDefaultStrategy(strategyId);
          }
      })
  })
}

function setNewDefaultStrategy(strategyId) {
  $.ajax({
    url: "/repricer_strategy/update_default",
    type: "POST",
    data: {
      strategy_id: strategyId
    },
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    success: function(resp) {
        if (resp.success == false) {
            flashError(resp.message);
        } else {
            flashSuccess("Strategy succesfully changed!");
            location.reload();
        }
    }
  });
}

function setupStrategyListeners() {
    $(document).on('ajax:success', '.strategy-form', function(e, data, status, xhr) {
        $("#newStrategyModal").modal("hide");
        $("#editStrategyModal").modal("hide");
        $('.modal-backdrop').remove();
        $('#strategies').DataTable().ajax.reload();
    }).on('ajax:error', '.strategy-form', function(e){
        var detail = e.detail;
        var error = detail[0];
        flashError(error["errors"]);
    });
}

function repricerDefaultStategyButton(strategyId) {
  var defaultStrategyId = $("#repricerDefaultStrategyId").val();

  if (strategyId == defaultStrategyId) {
    return '<svg class="action-btn action-btn-enabled" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>'
  } else {
    return `<svg data-id="${strategyId}" class="action-btn action-btn-delete set-default-strategy" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>`
  }
}

function setupStrategiesTable() {
  $('#strategies').DataTable({
      dom: getDom(),
      oLanguage: getLanguage(),
      processing: true,
      serverSide: true,
      pagingType: "numbers",
      fixedHeader: {
        headerOffset: 68
      },
      ajax: {
          url: "/repricer_strategy/fetch",
          type: "GET",
          data: function(d){
            d.search_phrase = $("#repricerStrategySearch").val()
          },
      },
      bFilter: false,
      bLengthChange: false,
      paging: true,
      pageLength: 20,
      ordering: false,
      columns: [
          {
            title: "id",
            data: "id",
            visible: false
          },
          {
            title: "Name",
            data: "name"
          },
          {
            title: "Min Margin",
            data: "min_margin",
            render: function(data) {
              return data.toString() + '% Margin';
            }
          },
          {
            title: "Max Margin",
            data: "max_margin",
            render: function(data) {
              return data.toString() + '% Margin';
            }
          },
          {
            title: "Reprice Against",
            data: "fight_buybox",
            render: function(data) {
              return competeAgainstWords(data);
            }
          },
          {
            title: "Price Adjustment",
            data: "price_adjustment"
          },
          {
            title: "No Competitor Strategy",
            data: "no_competitor_strategy",
            render: function(data){ 
                return readableStatus(data);
            }
          },
          {
              title: "",
              width: "30px",
              render: function(data, type, row){
                  return `${repricerDefaultStategyButton(row.id, row.organization_id)}
                          <a local="false" data-toggle="modal" data-target="#editStrategyModal" class="btn btn-icon" data-remote="true" href="/repricer_strategy/edit?id=${row.id}">
                            <svg class="action-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Edit" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                          </a>
                          <button class="btn btn-icon delete-btn" data-table="strategies" data-path="/repricer_strategy/" data-id="${row.id}"><svg class="action-btn" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button>`
              }
          }
      ],
  });
}

function competeAgainstWords(fight_buybox) {
  var word = 'Buy Box'
  if (!fight_buybox) word = 'Lowest Price'
  return word
}

function competeAgainstWalmartWords(fight_walmart) {
  var word = 'Yes'
  if (!fight_walmart) word = 'No'
  return word
}