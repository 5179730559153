import { delayed, flashError, flashSuccess } from 'packs/helper';

$(function() {
    setupTrackingListeners();
});

function setupTrackingListeners() {
    $(document).on('click', '#generateTracking', function () {
        var currentElement = this;
        Swal.fire({
            title: 'Are you sure?',
            text: "This will generate a valid tracking number and submit it. The status of the order will be sent to Shipped.",
            showCancelButton: true,
            confirmButtonColor: 'var(--success)',
            cancelButtonColor: 'var(--secondary)',
            confirmButtonText: 'Generate',
            showClass: {
                popup: '',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                $(currentElement).prop('disabled', true);
                var ids = $(currentElement).data('id');
                var mp = $(this).data('mp');
                generateTracking(currentElement, ids, mp);
            }
        })
    });

    $(document).on('click', '#saveTracking', function () {
        $(this).prop('disabled', true);
        var ids = $(this).data('id');
        var mp = $(this).data('mp');
        saveTracking(this, ids, mp);
    });

    $('#orderItemShipmentsModal').on('hidden', function () {
        $("#orderItemShipmentsModal").html("");
    })

    $(document).on("input", "#trackingNumber", delayed(function (e) {
        var tn = $(this).val();
        var tn_lowered = tn.toLowerCase();

        if (tn_lowered.indexOf("tba") >= 0) {
            $(".tba-feedback-msg").show();
        } else {
            $(".tba-feedback-msg").hide();
        }
    }, 300));

}

function saveTracking(button, ids, mp) {
    var url = '/walmart_shipments/add_tracking';
    if (mp == 'amazon') url = '/amazon_shipments/add_tracking';

    $.ajax({
        url: url,
        type: "POST",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            sku: $("#orderItemSku").val(),
            order_id: $("#orderId").val(),
            tracking_number: $("#trackingNumber").val()
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
                $(button).prop('disabled', false);
            } else {
                flashSuccess(resp.message);
                setStatusToShipped(ids, mp);
                $("#orderItemShipmentsModal").html("");
                $("#orderItemShipmentsModal").modal("hide");

                $("#amazonOrderItemShipmentsModal").html("");
                $("#amazonOrderItemShipmentsModal").modal("hide");
            }
        }
    });
}

function generateTracking(button, ids, mp) {
    var url = '/walmart_shipments/generate_tracking';
    if (mp == 'amazon') url = '/amazon_shipments/generate_tracking';

    $.ajax({
        url: url,
        type: "POST",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            order_id: $("#orderId").val()
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
                $(button).prop('disabled', false);
            } else {
                flashSuccess(resp.message);
                setStatusToShipped(ids, mp);
                $("#orderItemShipmentsModal").html("");
                $("#orderItemShipmentsModal").modal("hide");

                $("#amazonOrderItemShipmentsModal").html("");
                $("#amazonOrderItemShipmentsModal").modal("hide");
            }
        }
    });
}

function setStatusToShipped(ids, mp) {
    var tableName = '#orders';
    if (mp == 'amazon') tableName = '#amazonOrders';

    ids = ids.toString();
    var row = $(tableName).DataTable().row($("#order-row-" + ids.split(',')[0]).closest('tr'));
    var rowData = row.data();
    rowData.status = "shipped"
    row.data(rowData).draw(false);
}