import {  flashSuccess, flashError, fetchAmazonOrdersAnalytics } from 'packs/helper';

$(function() {
    setupAmazonOrdeItemEditListeners();
});

function setupAmazonOrdeItemEditListeners() {
    $(document).on("click", "#editAmazonOrderItemSave", function() {
        $(this).prop('disabled', true);
        saveAmazonOrderItem(this);
    })
}

function saveAmazonOrderItem(button) {
    $.ajax({
        url: "/amazon_order_items/update",
        type: "PATCH",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            order_item_ids: $("#editOrderItemIds").val(),
            status: $("#editAmazonStatus").val(),
            source_order_number: $("#editAmazonOrderNumber").val(),
            source_cost: $("#editAmazonItemCost").val(),
            source_shipping: $("#editAmazonShipping").val(),
            source_tax: $("#editAmazonSourceTax").val(),
            refunded_to_client: $("#editAmazonRefundedToClient").val(),
            refunded_from_supplier: $("#editAmazonSupplierRefund").val(),
            manual_adjustment: $("#amazonManualAdjustment").val(),
            notes: $("#editAmazonNotes").val()
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
            } else {
                var updatedOrder = resp.order[0]
                var row = $('#amazonOrders').DataTable().row($("#order-row-" + updatedOrder.order_item_id).closest('tr'));
                row.data(updatedOrder)
                fetchAmazonOrdersAnalytics();
                
                $("#amazonOrderItemEditModal").html("");
                $("#amazonOrderItemEditModal").modal("hide");
                flashSuccess(resp.message);
            }

            $(button).prop('disabled', false);
        }
    });
}