import { flashSuccess, flashError, handleTabsForView } from 'packs/helper';
var elements = stripe.elements();

$(function() {
    handleTabsForView();
    setupBillingListeners();
    setupStripeCardElement();
});

function setupStripeCardElement() {
    if ($('#stripeCardContainer').length == 0) return;

    var cardElement = elements.create('card');
    cardElement.mount('#stripeCardContainer');

    cardElement.on('change', function(event) {
        if (event.complete) {
            $("#saveNewCard").prop("disabled", false);
        }
    });
}

function setupBillingListeners() {
    $(document).on("click", "#saveNewCard", function() {
        $(this).prop('disabled', true);
        var cardElement = elements.getElement('card');

        stripe.createToken(cardElement).then(function(result) {
            if (result.error) {
                flashError(result.error)
            } else {
                saveNewCard(this, result.token);
            }
        });
    })

    $(document).on("click", "#removeCreditCard", function() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will need to add a new card to continue using Firecape without interuption.',
            showCancelButton: true,
            confirmButtonColor: 'var(--danger)',
            cancelButtonColor: 'var(--secondary)',
            confirmButtonText: "Delete",
            showClass: {
                popup: '',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                removeCreditCard($(this).data('id'));
            }
        }) 
    })

    $(document).on("click", ".choose-plan-btn", function() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to change your plan',
            showCancelButton: true,
            confirmButtonColor: 'var(--success)',
            cancelButtonColor: 'var(--secondary)',
            confirmButtonText: "Change Plan",
            showClass: {
                popup: '',
            },
        }).then((result) => {
            var selectedPlan = $(this).data('id');
            $(this).prop('disabled', true);
            saveSubscriptionPlan(this, selectedPlan);
        })
    });

    $(document).on("click", "#purchaseTrackingCredits", function() {
        var selectedPlan = $("#trackingCreditPlanSelect").val();
        $(this).prop('disabled', true);
        purhcaseTrackingCredits(this, selectedPlan);
    });
}

function saveNewCard(button, token) {
    $.ajax({
        url: "/billing/create_card",
        type: "POST",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            token: JSON.stringify(token)
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
            } else {                
                window.location.replace("/organization#billing")
                location.reload()
            }
        }
    });
}

function saveSubscriptionPlan(button, planId) {
    $.ajax({
        url: "/billing/update_plan",
        type: "PATCH",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            plan_id: planId
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
                $(button).prop('disabled', false);
            } else {
                flashSuccess("Success! Your plan has been changed!");
                $('#billing .btn-secondary').removeClass('btn-secondary').addClass('btn-success').text('Choose Plan').parent().parent().find(".card-header").find(".card-price").css("border","2px solid #bfc9d4");
                $(button).prop('disabled', false);
                $(button).removeClass('btn-success').addClass('btn-secondary');
                $(button).text('Current Plan');
                $(button).parent().parent().find(".card-header").find(".card-price").css("border","2px solid var(--success)")
            }
        }
    });
}

function purhcaseTrackingCredits(button, planId) {
    $.ajax({
        url: "/billing/purchase_tracking_credits",
        type: "POST",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            plan_id: planId
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
                $(button).prop('disabled', false);
            } else {                
                window.location.replace("/organization#billing")
                location.reload()
            }
        }
    });
}
