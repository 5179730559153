$(document).on('click', '.delete-btn', function() {
  deleteNode(this);
});

$(document).on('click', '#helpButton', function() {
  var beacon = $(".BeaconContainer");

  if (beacon.is(":visible")) {
    Beacon("close");
  } else {
    Beacon("open");
  }
});

$(document).on('click', '.copy-btn', function() {
  var copyText = $(this).parent().find(".copy-btn-span").text();
  var successIcon = $(this).next('.copy-btn-success')
  var copyIcon = $(this)
  copyIcon.hide();
  successIcon.show();

  setTimeout(function(){ 
    successIcon.hide();
    copyIcon.show();
  }, 1000);

  copyTextToClipboard(copyText);
});

$(document).on('click', '.copy-data-btn', function() {
  var copyText = $(this).data('text')
  var successIcon = $(this).next('.copy-btn-success')
  var copyIcon = $(this)
  copyIcon.hide();
  successIcon.show();

  setTimeout(function(){ 
    successIcon.hide();
    copyIcon.show();
  }, 1000);

  copyTextToClipboard(copyText);
});

$(document).on('click', '.copyable', function() {
  var copyText = $(this).text();
  copyTextToClipboard(copyText);
});

$(document).on('click', '.input-copy', function() {
  var copyText = $(this).parent().parent().find('.input-copyable').val();
  copyTextToClipboard(copyText);
});

export const readableStatus = function(str) {
  var i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export const handleTabsForView = function() {
  var hash = location.hash.replace(/^#/, '');
  if (hash) {
      $('.nav-tabs a[href="#' + hash + '"]').tab('show');
      window.scrollTo(0, 0);
  } 
}

export const toMoney = function(val, decimals = 2, makeNegative = false) {
  if (makeNegative) val = val * -1

  var returnVal = $.fn.dataTable.render.number(',', '.', decimals, '$').display(val)
  return returnVal;
}

export const formatNumberWithMetricPrefix = function(num, digits = 1) {

  const si = [
    {value: 1e18, symbol: 'E'},
    {value: 1e15, symbol: 'P'},
    {value: 1e12, symbol: 'T'},
    {value: 1e9, symbol: 'G'},
    {value: 1e6, symbol: 'M'},
    {value: 1e3, symbol: 'k'},
    {value: 0, symbol: ''},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  function divideNum(divider) {
    return (num / (divider || 1)).toFixed(digits);
  }
 
  let i = si.findIndex(({value}) => num >= value);
  
  if (+divideNum(si[i].value) >= 1e3 && si[i - 1]) {
    i -= 1;
  }
  const {value, symbol} = si[i];
  return divideNum(value).replace(rx, '$1') + symbol;
}

export const getFormattedDate = function(date) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}

export const fetchOrdersAnalytics = function(){
  if (!($('#orderDateRangePicker').data('daterangepicker'))) return;

  var start = $('#orderDateRangePicker').data('daterangepicker').startDate.format('YYYY-MM-DD');
  var end = $('#orderDateRangePicker').data('daterangepicker').endDate.format('YYYY-MM-DD');
  
  $.ajax({
      url: "/orders/fetch_analytics",
      type: "GET",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: {
          search_phrase: $("#ordersSearch").val(),
          status: $("#ordersStatusFilter").val(),
          date_range: [start, end]
      },
      success: function(resp) {
          if (resp.success == false) {
              flashError("Error Fetching Analytics");
          } else {
              setAnalyticsCards(resp.analytics[0]);
          }
      }
  });
}

export const fetchAmazonOrdersAnalytics = function(){
  if (!($('#amazonOrderDateRangePicker').data('daterangepicker'))) return;

  var start = $('#amazonOrderDateRangePicker').data('daterangepicker').startDate.format('YYYY-MM-DD');
  var end = $('#amazonOrderDateRangePicker').data('daterangepicker').endDate.format('YYYY-MM-DD');
  
  $.ajax({
      url: "/orders/fetch_amazon_analytics",
      type: "GET",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: {
          search_phrase: $("#amazonOrdersSearch").val(),
          status: $("#amazonOrdersStatusFilter").val(),
          channel: $("#amazonOrdersChannelFilter").val(),
          late: $("#amazonOrdersToggleLate").hasClass("active"),
          ship_today: $("#amazonOrdersToggleShipToday").hasClass("active"),
          date_range: [start, end]
      },
      success: function(resp) {
          if (resp.success == false) {
              flashError("Error Fetching Analytics");
          } else {
              setAnalyticsCards(resp.analytics[0]);
          }
      }
  });
}

/*
*  Fix to 0 decimals for percent and add symbol
*/ 
export const toPercent = function(val) {
  var returnVal = (val || 0).toFixed(2).toString() + '%' 
  return returnVal;
}

export const trimChars = function(str, length = 150) {
  if (!str) return "";

  if (str.length <= length) {
      return str;
  }
  
  return str.substr(0, length) + '\u2026'
}

export const getDom = function () {
  return "<'dt--top-section'<'row'<'col-12 col-sm-6 d-flex justify-content-sm-start px-2 justify-content-center mt-sm-0 mt-3'f>>>" +
         "<'table-responsive'tr>" +
         "<'dt--bottom-section d-sm-flex justify-content-sm-between text-center'<'dt--pages-count  mb-sm-0 mb-3'i><'dt--pagination'p>>"
}

export const getLanguage = function () {
  return {
            oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
            sInfo: "Showing _START_ - _END_ of _TOTAL_",
            sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            sSearchPlaceholder: "Search...",
            sLengthMenu: "Results :  _MENU_",
            sInfoFiltered: "",
        }
}

export const getStatusBadge = function(status) {
  switch (status) {
    case "Cancelled":
      return makeStatusBadge(status, 'secondary')
    case "Refunded":
        return makeStatusBadge(status, 'secondary')
    case "Out of Stock":
        return makeStatusBadge(status, 'danger')
    case "request_return":
      return makeStatusBadge(status, 'dark')
    case "New":
      return makeStatusBadge(status, 'info')
    case "Ordered":
      return makeStatusBadge(status, 'warning')
    case "Created":
      return makeStatusBadge(status, 'primary')
    case "Shipped":
      return makeStatusBadge(status, 'success')
    default:
      return makeStatusBadge(status, 'success')
  }
}

export const getBadgeColor = function(status) {
  switch (status) {
    case "cancelled":
      return 'secondary'
    case "refunded":
      return 'secondary'
    case "out_of_stock":
      return 'danger'
    case "request_return":
      return 'dark'
    case "ordered":
        return 'warning'
    case "new":
      return 'info'
    case "created":
      return 'primary'
    case "shipped":
      return 'success'
    default:
      return 'success'
  }
}

export const timeSince = function(date) {
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export const flashError = function(msg) {
  $(".toast").remove();
  $('body').append('<div style="position: absolute; bottom: 30px; left: 50%; z-index: 9999; transform: translate(-50%, -50%);">' +
                        '<div class="toast fade" data-autohide="true" role="alert" data-delay="500" aria-live="assertive" aria-atomic="true">' +
                            '<div class="toast-body rounded bg-danger">' +
                                msg +
                            '</div>' +
                        '</div>' +
                     '</div>');

  $(".toast").toast({ delay: 2500 });
  $(".toast").toast('show');
}

export const flashSuccess = function(msg) {
    $(".toast").remove();
    $('body').append('<div style="position: absolute; bottom: 30px; left: 50%; z-index: 9999; transform: translate(-50%, -50%);">' +
                          '<div class="toast fade" data-autohide="true" role="alert" data-delay="500" aria-live="assertive" aria-atomic="true">' +
                              '<div class="toast-body rounded bg-success">' +
                                  msg +
                              '</div>' +
                          '</div>' +
                      '</div>');

    $(".toast").toast({ delay: 2500 });
    $(".toast").toast('show');
}

export const getParam = function(url, param) {
    var url = new URL(url); 
    var val = url.searchParams.get(param); 
    return val;
}

export const setParam = function(key, val) {
    const urlParams = new URLSearchParams(window.location.search);
    if (val == 'false' || val != "") {
        urlParams.set(key, val);
    } else {
        urlParams.delete(key);
    }
    window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${urlParams}`));
}

export const deleteNode = function(element) {
    var path = $(element).attr("data-path")
    var id = $(element).attr("data-id")
    var table = $(element).attr("data-table")

    Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to delete this?",
        showCancelButton: true,
        confirmButtonColor: 'var(--danger)',
        cancelButtonColor: 'var(--secondary)',
        confirmButtonText: "Delete",
        showClass: {
            popup: '',
        },
    }).then((result) => {
        if (result.isConfirmed) {
            $.ajax({
              url: path + id,
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              method: 'DELETE'
            }).done(function() {
                if (table) $('#' + table).DataTable().ajax.reload();
            }).fail(function(xhr) {
              flashError(xhr.responseJSON["errors"]);
            })
        }
    })
}

export const delayed = function(callback, ms) {
  var timer = 0;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}

export const loadetBtnDiv = function() {
  return '<div class="spinner-border text-white mr-2 align-self-center loader-xsm "></div>';
}

// Helpers helpers ;)
function makeStatusBadge(status, color) {
  return '<span class="badge badge-' + color + '">' + status + '</span>';
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.trigger('focus');
  textArea.select();

  document.execCommand('copy');
  document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text)
}

function setAnalyticsCards(analytics) {
    var sourceCost = Number(analytics.total_source_cost)
    var sourceShipping = Number(analytics.total_source_shipping)
    var sourceTax = Number(analytics.total_source_tax)
    var totalFees = Number(analytics.total_fees)
    var totalRevenue = Number(analytics.total_revenue)
    var totalShipping = Number(analytics.total_shipping)
    var totalTax = Number(analytics.total_tax)

    var supplierCost = sourceCost + sourceShipping + sourceTax
    var grossSales = totalRevenue + totalTax
    var netPayable = totalRevenue + totalShipping - totalFees
    var profit = netPayable - supplierCost
    var margin = parseFloat((profit / (totalRevenue || 1)) * 100).toFixed(2)
    var roi = parseFloat((profit / (supplierCost || 1)) * 100).toFixed(2)

    $("#ordersGrossSales").text(toMoney(grossSales))
    $("#ordersShipping").text(toMoney(totalShipping))
    $("#ordersTaxCollected").text(toMoney(totalTax))
    $("#ordersFees").text(toMoney(totalFees))
    $("#ordersNetPayable").text(toMoney(netPayable))
    $("#ordersSupplierCost").text(toMoney(supplierCost))
    $("#ordersProfit").text(toMoney(profit))
    $("#ordersMargin").text(margin.toString() + '%')
    $("#ordersRoi").text(roi.toString() + '%')
}