import { flashSuccess, flashError, delayed, setParam, getParam, trimChars, getDom, getLanguage, toMoney, timeSince } from 'packs/helper';
import { getImageDiv, listingSourceStatusBadge } from 'packs/shared/listings';

var amazonProductsTable;

$(function() {
    setupAmazonProductsTable();
    setupProductResearch();
});

function setupAmazonProductsTable() {
    amazonProductsTable = $('#amazonProducts').DataTable({
        dom: getDom(),
        rowId: 'id',
        sScrollX: true,
        oLanguage: getLanguage(),
        processing: true,
        serverSide: true,
        fixedHeader: {
            headerOffset: 68
        },
        pagingType: "numbers",
        ajax: {
            url: "/product_research",
            type: "GET",
            data: function(d){
                d.search_phrase = $("#amazonProductSearch").val(),
                d.min_sales_rank = getParam(window.location.href, "min_sales_rank"),
                d.max_sales_rank = getParam(window.location.href, "max_sales_rank"),
                d.min_reviews = getParam(window.location.href, "min_reviews"),
                d.max_reviews = getParam(window.location.href, "max_reviews"),
                d.min_match_quality = getParam(window.location.href, "min_match_quality"),
                d.max_match_quality = getParam(window.location.href, "max_match_quality"),
                d.marketplaces = getSelectedMarketplaces();
                d.import_id = $("#currentImportId").val();
            },
        },
        bLengthChange: false,
        bFilter: false,
        paging: true,
        pageLength: 25,
        ordering: true,
        order: [[ 7, "desc" ]],
        columns: [
            {
                title: "id",
                data: "id",
                name: "id",
                visible: false
            },
            {
                title: "Amazon",
                orderable: false,
                render: function(data, type, row){
                    var html =  `<div class="d-flex" style="align-items: center;"> 
                                    ${getImageDiv(row.amazon_image_url)}
                                    <div class="float-left">
                                        <a target="_blank" href="https://amazon.com/dp/${row.amazon_sku}" class="align-self-center mb-0">${trimChars(row.amazon_name, 40)} <svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                                        <p class="align-self-center mb-0">${row.brand}</p>
                                        <p class="align-self-center mb-0">${row.amazon_sku}</p>
                                        <p class="align-self-center mb-0">${toMoney(row.amazon_price) || "--.--"}</p>
                                    </div>
                                </div>`

                    return html
                }
            },
            {
                title: "Walmart",
                orderable: false,
                render: function(data, type, row){
                    var html =  `<div class="d-flex" style="align-items: center;"> 
                                    ${getImageDiv(row.source_image_url)}
                                    <div class="float-left">
                                        <a target="_blank" href="https://walmart.com/ip/${row.source_sku}" class="align-self-center mb-0">${trimChars(row.source_name, 40)} <svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                                        <p class="align-self-center mb-0">${listingSourceStatusBadge(row.source_in_stock, true)}</p>
                                        <p class="align-self-center mb-0">${row.source_sku}</p>
                                        <p class="align-self-center mb-0">${toMoney(row.source_price) || "--.--"}</p>
                                    </div>
                                </div>`

                    return html
                }
            },
            {
                title: "Profit",
                class: "text-right",
                data: "profit",
                name: "profit",
                orderable: true,
                render: function(data, type, row) {
                    if (data < 0) {
                        return `<span class="badge badge-danger">${toMoney(data || "--.--")}</span>`;
                    } else {
                        return `<span class="badge badge-success">${toMoney(data || "--.--")}</span>`;
                    }
                }
            },
            {
                title: "Category",
                class: "text-right",
                data: "category",
                orderable: false,
                render: function(data, type, row) {
                    return `<p class="align-self-center mb-0">${data}</p>`
                }
            },
            {
                title: "Buy Box",
                class: "text-center",
                data: "buy_box_type",
                orderable: false,
                render: function(data, type, row) {
                    switch(data) {
                        case "AMZ":
                            return "Amazon";
                        case "FBA":
                            return "FBA";
                        default:
                            return "FBM";
                    }
                }
            },
            {
                title: "Rank",
                class: "text-right",
                data: "sales_rank",
                name: "sales_rank",
                orderable: true,
                render: function(data, type, row) {
                    return data.toLocaleString();
                }
            },
            {
                title: "Checked",
                class: "text-center",
                data: "source_last_checked_at",
                name: "source_last_checked_at",
                orderable: true,
                render: function(data, type, row) {
                    var d = new Date(row.source_last_checked_at);
                    return timeSince(d) + ' ago';
                }
            },
            {
                title: "",
                orderable: false,
                render: function(data, type, row){
                    return `<a data-toggle="modal" data-target="#viewInfoModal" class="btn btn-icon view-info-button" data-remote="true" href="/product_research/view_info?id=${row.id}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                            </a>`
                }
            }
        ],
    });
}

function setupProductResearch() {
    
    $(document).on("click", ".add-research-product", function(e) {
        var product_id = $(this).data("product-id");
        
        addProductToListings(product_id, this);
    });

    $(document).on("input", ".research-sales-rank-range", delayed(function (e) {
        var type = $(this).data("type")

        if (type == "min") {
            setParam("min_sales_rank", $(this).val());
        } else {
            setParam("max_sales_rank", $(this).val());
        }

        reloadProductResearch();
    }, 1000));

    $(document).on("input", ".amazon-product-search", delayed(function (e) {
        setParam("search_phrase", $(this).val());
        reloadProductResearch();
    }, 1000));

    $(document).on("input", ".research-reviews-range", delayed(function (e) {
        var type = $(this).data("type")

        if (type == "min") {
            setParam("min_reviews", $(this).val());
        } else {
            setParam("max_reviews", $(this).val());
        }

        reloadProductResearch();
    }, 1000));

    $(document).on("input", ".research-match-quality-range", delayed(function (e) {
        var type = $(this).data("type")

        if (type == "min") {
            setParam("min_match_quality", $(this).val());
        } else {
            setParam("max_match_quality", $(this).val());
        }
        
        reloadProductResearch();
    }, 1000));

    $(".checkbox-info").on("click", function(e) {
        e.stopPropagation();
        reloadProductResearch();
    });

    $("#clearResearchFilters").on("click", function(e) {
        $(".range-input").val("");
        $(".seller-type-checkbox").each(function(index, element) {
            $(element).prop("checked", true);
        });

        window.history.replaceState(null, null, window.location.pathname);
        reloadProductResearch();
    });
}

function reloadProductResearch(){
    amazonProductsTable.ajax.reload();
}

function addProductToListings(product_id, button) {
    $(button).prop("disabled", true);

    $.ajax({
        url: "/product_research/add_to_listings",
        type: "POST",
        data: {
          id: product_id
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
            } else {
                flashSuccess("Product Successfully Added!");
                
                $("#" + product_id + " .add-research-product").hide();
                $("#" + product_id + " .view-info-button").hide();
                $("#" + product_id + " .hidden-added-button").show();
                $("#viewInfoModal").modal("hide");
                reloadProductResearch();
            }

            $(button).prop("disabled", false);
        }
    });
}

function getSelectedMarketplaces() {
    var marketplaces = [];
    $(".seller-type-checkbox:checked").each(function(index, element) {
        marketplaces.push($(element).data("seller-type"));
    });

    return marketplaces;
}