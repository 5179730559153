require('datatables.net-bs4')
var managerViewTable;
var managerViewSalesTable;

import { toMoney, toPercent, getDom, getLanguage, getFormattedDate } from 'packs/helper';

$(function() {
    setManagerView();
    getManagerViewData();
    getManagerViewSalesData();
});

function setManagerView() {
    var today = new Date()
    var startOfMonth = new Date()
    var thirtyDaysAgo = new Date()
    var sevenDaysAgo = new Date()
    var baseLastMonth = new Date()
    var lastMonthEnd = baseLastMonth.setDate(0)
    var lastMonthStart = baseLastMonth.setDate(1)
    startOfMonth.setDate(1)
    thirtyDaysAgo.setDate(today.getDate() - 30)
    sevenDaysAgo.setDate(today.getDate() - 7)

    $('#managerViewDatePicker span').html(getFormattedDate(sevenDaysAgo) + ' - ' + getFormattedDate(today));

    $('#managerViewDatePicker').daterangepicker({
        startDate: sevenDaysAgo,
        endDate: today,
        ranges: {
            'Lifetime': [new Date(null), today],
            'Last 7 Days': [sevenDaysAgo, today],
            'Last 30 Days': [thirtyDaysAgo, today],
            'This Month': [startOfMonth, today],
            'Last Month': [lastMonthStart, lastMonthEnd],
        }
    });

    $('#managerViewDatePicker').on('apply.daterangepicker', function(ev, picker) {
        $('#managerViewDatePicker span').html(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        getManagerViewData()
        getManagerViewSalesData();
    });

    $("#manager-sales-tab").on('click', function() {
        $("#managerViewChannelFilterDiv").css("display", "inline-block");
    });

    $("#manager-orders-tab").on('click', function() {
       $("#managerViewChannelFilterDiv").hide();
    });

    $('#managerViewChannelFilter').on("input", function (e) {
      getManagerViewSalesData();
    });
}

function getManagerViewData() {
    if (!$('#managerViewDatePicker').data('daterangepicker')) return;

    var start = $('#managerViewDatePicker').data('daterangepicker').startDate.format('YYYY-MM-DD');
    var end = $('#managerViewDatePicker').data('daterangepicker').endDate.format('YYYY-MM-DD');

    $.ajax({
        url: "/manager_view/stats",
        type: "GET",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
          date_range: [start, end]
        },
        success: function(resp) {
            setManagerViewTotals(resp.totals);

            if (managerViewTable){
                $('#managerView').DataTable().clear().rows.add(resp.data).draw();
            } else {
                setupManagerViewTable(resp.data);
            }
        }
    });
}

function getManagerViewSalesData() {
  if (!$('#managerViewDatePicker').data('daterangepicker')) return;

  var start = $('#managerViewDatePicker').data('daterangepicker').startDate.format('YYYY-MM-DD');
  var end = $('#managerViewDatePicker').data('daterangepicker').endDate.format('YYYY-MM-DD');

  $.ajax({
      url: "/manager_view/sales_stats",
      type: "GET",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: {
        date_range: [start, end],
        channel: $("#managerViewChannelFilter").val()
      },
      success: function(resp) {
          setManagerViewSalesTotals(resp.totals);
          if (managerViewSalesTable){
              $('#managerViewSalesTable').DataTable().clear().rows.add(resp.data).draw();
          } else {
              setupManagerViewSalesTable(resp.data);
          }
      }
  });
}

function setManagerViewTotals(totals){
    $("#totalOrders").text(totals.total_orders);
    $("#newOrders").text(totals.new_orders);
    $("#shipByTodayOrders").text(totals.ship_by_today_orders);
    $("#lateOrders").text(totals.late_orders);
}

function setManagerViewSalesTotals(totals){
    $("#totalRevenue").text(toMoney(totals.total_revenue || 0));
    $("#totalCost").text(toMoney(totals.total_cost || 0));
    $("#totalProfit").text(toMoney(totals.total_profit || 0));
    $("#totalRoi").text(toPercent((totals.total_profit / totals.total_cost) * 100));
}

function setupManagerViewTable(data) {
    managerViewTable = $('#managerView').DataTable({
        dom: getDom(),
        oLanguage: getLanguage(),
        pagingType: "numbers",
        fixedHeader: {
          headerOffset: 68
        },
        data: data,
        bLengthChange: false,
        paging: false,
        ordering: true,
        order: [1],
        columnDefs: [
          { orderable: false, targets: [0] },
        ],
        columns: [
            {
              title: "Store",
              data: "store_name",
              orderable: false
            },
            {
              title: "Total",
              data: "total_orders"
            },
            {
              title: "New",
              data: "new"
            },
            {
              title: "Ordered",
              data: "ordered"
            },
            {
              title: "Late",
              data: "late"
            },
            {
              title: "Ship Today",
              data: "ship_by_today"
            },
            {
              title: "Refunded",
              data: "refunded"
            },
            {
              title: "Cancelled",
              data: "cancelled"
            },
            {
              title: "OOS",
              data: "out_of_stock"
            },
            {
              title: "Shipped",
              data: "shipped"
            },
            {
              title: "Request Return",
              data: "request_return"
            }
        ],
    });
}

function setupManagerViewSalesTable(data) {
    managerViewSalesTable = $('#managerViewSalesTable').DataTable({
        dom: getDom(),
        oLanguage: getLanguage(),
        pagingType: "numbers",
        fixedHeader: {
          headerOffset: 68
        },
        data: data,
        bLengthChange: false,
        paging: false,
        ordering: true,
        order: [1],
        columnDefs: [
          { orderable: false, targets: [0] },
        ],
        columns: [
            {
              title: "Store",
              data: "store_name",
              orderable: false
            },
            {
              title: "Revenue",
              data: "total_revenue",
              render: function(data, type, row) {
                return toMoney(data);
              }
            },
            {
              title: "Cost",
              data: "total_cost",
              render: function(data, type, row) {
                return toMoney(data);
              }
            },
            {
              title: "Profit",
              data: "total_profit",
              render: function(data, type, row) {
                return toMoney(data);
              }
            },
            {
              title: "ROI",
              render: function(data, type, row) {
                return toPercent((row.total_profit / row.total_cost) * 100);
              }
            }
        ],
    });
}
