import {flashError, flashSuccess } from 'packs/helper';

$(function() {
    setupOnboardingView();
    setupOnboardingListeners();
});

function setupOnboardingView() {
    var currentStep = $('#onboardingNextStep').data('step');
    switch (currentStep) {
        case 1:
            $("#firstName").trigger('focus');
            break;
        case 2:
            $("#storeName").trigger('focus');
            break;
        default:
            break;
    }
}

function setupOnboardingListeners() {
    $(document).on('click', '#onboardingNextStep', function() {
        $(this).prop('disabled', true);
        completeStepOne(this);
    });

    $(document).on('click', '#finishOnboarding', function() {
        $(this).prop('disabled', true);
        completeStepTwo(this);
    });

    $(document).on('click', '.change-account-type', function() {
        $("#changeAccountType").hide(200);
        $("#amazonAccountInfo").hide(200);
        $("#walmartAccountInfo").hide(200);
        $("#trackingAccountInfo").hide(200);
        $("#storeName").hide(200);
        $("#finishOnboarding").hide(200);
        $("#accountTypeChooser").show(200);
    });

    $(document).on('click', '#amazonStoreSelect', function() {
        $("#amazonAccountInfo").show(200);
        $("#changeAccountType").hide(200);
        $("#walmartAccountInfo").hide(200);
        $("#trackingAccountInfo").hide(200);
        $("#storeName").hide(200);
        $("#finishOnboarding").hide(200);
        $("#accountTypeChooser").hide(200);
    });

    $(document).on('click', '#walmartStoreSelect', function() {
        $("#changeAccountType").show(200);
        $("#storeName").show(200);
        $("#finishOnboarding").show(200);
        $("#walmartAccountInfo").show(200);
        $("#amazonAccountInfo").hide(200);
        $("#accountTypeChooser").hide(200);
    });

    $(document).on('click', '#trackingStoreSelect', function() {
        $("#changeAccountType").show(200);
        $("#storeName").show(200);
        $("#finishOnboarding").show(200);
        $("#amazonAccountInfo").hide(200);
        $("#walmartAccountInfo").hide(200);
        $("#accountTypeChooser").hide(200);
    });

    $(document).on('click', '#connectAmazonStore', function() {
        Swal.fire({
            title: 'Finish',
            text: 'Complete your onboarding on Amazon by allowing Firecape permissions to your store',
            showCancelButton: true,
            confirmButtonColor: 'var(--success)',
            cancelButtonColor: 'var(--secondary)',
            confirmButtonText: "My store is connected",
            showClass: {
                popup: '',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                location.reload()
            }
        })
        
        window.open("https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.149309fc-6fba-412a-9ad1-4be85bf37195&version=beta");
    });
}

function completeStepOne(button) {
    $.ajax({
        url: "/onboarding/save_step_one",
        type: "POST",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            first_name: $("#firstName").val(),
            last_name: $("#lastName").val(),
            store_name: $("#storeName").val(),
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
            } else {
                goToNextOnboardingStep(1);
            }
            $(button).prop('disabled', false);
        }
    });
}

function completeStepTwo(button) {
    var type = 'tracking';
    if ($("#walmartAccountInfo").is(':visible')) type = 'walmart';

    $.ajax({
        url: "/onboarding/save_step_two",
        type: "POST",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            store_name: $("#storeName").val(),
            store_type: type,
            client_id: $("#walmartClientId").val(),
            client_secret: $("#walmartClientSecret").val()
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
            }
            $(button).prop('disabled', false);
        }
    });
}

function goToNextOnboardingStep(currentStep) {
    switch (currentStep) {
        case 1:
            $("#onboardingStepTwo").show();
            $("#onboardingPreviousStep").show();
            $("#onboardingStepOne").hide();
            $('#onboardingNextStep').data('step', 2);
            $('#onboardingPreviousStep').data('step', 2);
            $("#onboardingProgressBar").width('66%');
            $("#currentStepTitle").text('2')
            $("#storeName").trigger('focus');
            $("#onboardingNextStep").hide();
            break;
        default:
            break;
    }
}

function goToPreviousOnboardingStep(currentStep) {
    switch (currentStep) {
        case 2:
            $("#onboardingStepTwo").hide();
            $("#onboardingPreviousStep").hide();
            $("#onboardingStepOne").show();
            $('#onboardingNextStep').data('step', 1);
            $('#onboardingPreviousStep').data('step', 1);
            $("#onboardingProgressBar").width('33%');
            $("#currentStepTitle").text('1')
            $("#firstName").trigger('focus');
            $("#finishOnboarding").hide();
            $("#onboardingNextStep").show();
            break;
        default:
            break;
    }
}