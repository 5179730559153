// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import Swal from 'sweetalert2';
import '@stripe/stripe-js';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("packs/perfect-scrollbar.min.js")
require("packs/app")
require("packs/custom.js")
require("packs/snackbar.min.js")
require("packs/custom-snackbar.js")
require('datatables.net-bs4')
require('packs/fixedheader.js')
require('bootstrap-select')
require('daterangepicker')
require("packs/helper.js")
require('packs/walmart_listings/index.js')
require('packs/amazon_listings/index.js')
require('packs/walmart_order_items/main.js')
require('packs/walmart_orders/index.js')
require('packs/walmart_orders/edit.js')
require('packs/amazon_orders/edit.js')
require('packs/amazon_order_items/main.js')
require('packs/amazon_orders/index.js')
require('packs/billing/index.js')
require('packs/admin/index.js')
require('packs/dashboard/index.js')
require('packs/onboarding/index.js')
require('packs/tracking/index.js')
require('packs/manager_view/index.js')
require('packs/repricer/index.js')
require('packs/listings/new.js')
require('packs/listings/edit.js')
require('packs/listings/history.js')
require('packs/organization/users.js')
require('packs/organization/sku_costs.js')
require('packs/organization/auto_confirmer.js')
require('packs/product_research/index.js')
require('packs/shared/orders.js')
require('packs/shared/order_edit.js')
require('packs/shared/listings.js')

document.addEventListener("turbolinks:load", () => {
  window.Swal = Swal;
  $('[data-toggle="popover"]').popover()
  $('[data-toggle="selectpicker"]').selectpicker();
  $('.toast').toast({ autohide: false })
  $('#toast').toast('show');
  $(document).tooltip({selector: '[data-toggle="tooltip"]',
                       delay: { show: 300, hide: 100 },
                       boundary: 'window',
                       template: '<div class="tooltip status rounded-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'})
})