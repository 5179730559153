import { flashSuccess, flashError, getDom, getLanguage, timeSince } from 'packs/helper';

var taImportsTable;

$(function() {
  setupTaImportsTable();
});

function setupTaImportsTable() {
  taImportsTable = $('#taImports').DataTable({
    dom: getDom(),
    rowId: 'id',
    sScrollX: true,
    oLanguage: getLanguage(),
    processing: true,
    serverSide: true,
    fixedHeader: {
      headerOffset: 68
    },
    pagingType: "numbers",
    ajax: {
      url: "/admin/imports",
      type: "GET",
    },
    bLengthChange: false,
    bFilter: false,
    paging: true,
    pageLength: 25,
    ordering: false,
    columns: [
      {
        title: "id",
        data: "id",
        visible: false
      },
      {
        title: "Name",
        class: "text-left",
        data: "file_name"
      },
      {
        title: "Type",
        class: "text-left",
        data: "import_type"
      },
      {
        title: "Status",
        class: "text-left",
        data: "status"
      },
      {
        title: "Error",
        class: "text-left",
        data: "error_message"
      },
      {
        title: "Created",
        class: "text-right",
        data: "error_message",
        render: function(data, type, row) {
          var d = new Date(row.created_at);
          return timeSince(d) + ' ago';
        }
      },
    ],
  });
}


