import { flashError } from 'packs/helper';

$(function() {
    $(document).on("click", "#viewConfirmationEmailBtn", function() {
        getConfirmationEmail();
    })
});

function getConfirmationEmail() {
    $.ajax({
        url: "/organization/confirmation_email",
        type: "GET",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: function(resp) {
            if (resp.success == false) {
                flashError("No confirmation email found");
            } else {
                $("#confirmationEmail").text(resp.email_body)
                $("#viewConfirmationEmailModal").modal("show");
            }
        }
      });
}