import { flashError, flashSuccess, getDom, getLanguage } from 'packs/helper';

$(function() {
    setupTrackingView();
    setupTrackingListeners();
    setupTrackingDatatable();
});

function setupTrackingView() {
    var today = new Date();
    var tomorrow = new Date();
    var fourFromToday = new Date();
    var tenFromToday = new Date();
    tomorrow.setDate(today.getDate() + 1);
    fourFromToday.setDate(today.getDate() + 4);
    tenFromToday.setDate(today.getDate() + 10);
    
    $('#trackingShipDatePicker').daterangepicker({
        startDate: today,
        singleDatePicker: true,
        autoApply: true
    });

    $('#trackingDeliveryDatePicker').daterangepicker({
        startDate: fourFromToday,
        endDate: tenFromToday,
        minDate: tomorrow
    });
}

function setupTrackingListeners() {
    $(document).on('ajax:success', '.generate-tracking-form', function(e, data, status, xhr) {
        $('#generatedTracking').DataTable().ajax.reload()
    }).on('ajax:error', '.generate-tracking-form', function(e){
        var detail = e.detail;
        var error = detail[0];
        flashError(error["errors"]);

        if (error["errors"] == 'No tracking credits remaining, please purchase more credits.') {
            window.location = "/settings#billing"
        }
    });
}

function setupTrackingDatatable() {
    $('#generatedTracking').DataTable({
        dom: getDom(),
        oLanguage: getLanguage(),
        processing: true,
        serverSide: true,
        pagingType: "numbers",
        ajax: {
            url: "/tracking/fetch",
            type: "GET"
        },
        bFilter: false,
        bLengthChange: false,
        paging: true,
        pageLength: 20,
        ordering: false,
        columns: [
            {
                title: "id",
                data: "id",
                visible: false
            },
            {
                title: "Carrier",
                render: function(data) {
                    return 'FedEx';
                }
            },
            {
                title: "Tracking Number",
                data: "tracking_number",
                render: function(data) {
                    return '<a target="_blank" href=https://www.fedex.com/fedextrack/?trknbr=' + data + '>' + data + '</a>';
                }
            },
            {
                title: "Ship Date",
                data: "ship_date"
            },
            {
                title: "City",
                data: "city"
            },
            {
                title: "State",
                data: "state"
            },
            {
                title: "Created",
                data: "created_by_name"
            }
        ],
    });
}