import Chart from 'chart.js/auto';
import { toMoney, toPercent, formatNumberWithMetricPrefix, getFormattedDate, getLanguage, getDom, trimChars, getParam, setParam } from 'packs/helper';
var dashboardChart;

$(function() {    
    setupDashboardListeners();
    fetchDashboardData();
    fetchTopProducts();
});

function setupDashboardListeners() {
  var today = new Date()
  var startOfMonth = new Date()
  var thirtyDaysAgo = new Date()
  var sevenDaysAgo = new Date()
  var baseLastMonth = new Date()
  var lastMonthEnd = baseLastMonth.setDate(0)
  var lastMonthStart = baseLastMonth.setDate(1)
  startOfMonth.setDate(1)
  thirtyDaysAgo.setDate(today.getDate() - 30)
  sevenDaysAgo.setDate(today.getDate() - 7)

  $('#dashboardDateRangePicker span').html(getFormattedDate(thirtyDaysAgo) + ' - ' + getFormattedDate(today));

  $('#dashboardDateRangePicker').daterangepicker({
      startDate: thirtyDaysAgo,
      endDate: today,
      ranges: {
          'Lifetime': [new Date(null), today],
          'Last 7 Days': [sevenDaysAgo, today],
          'Last 30 Days': [thirtyDaysAgo, today],
          'This Month': [startOfMonth, today],
          'Last Month': [lastMonthStart, lastMonthEnd],
      }
  });

  $('#dashboardDateRangePicker').on('apply.daterangepicker', function(ev, picker) {
    $('#dashboardDateRangePicker span').html(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    dashboardChart.destroy();
    fetchDashboardData();
    $('#topProducts').DataTable().ajax.reload();
  });

  $("#amazonDashboardChannelFilter").val(getParam(window.location.href, "channel") || 'fbm')

  $('#amazonDashboardChannelFilter').on("input", function (e) {
    setParam("channel", $(this).val());
    dashboardChart.destroy();
    fetchDashboardData();
    $('#topProducts').DataTable().ajax.reload();
  });

  $('.complete-step-btn').on("click", function (e) {
    var step = $(this).data('step');
    completeStep(step, this);
  });
}

function completeStep(step, btn) {
    $.ajax({
        url: "/dashboard/complete_step",
        type: "POST",
        beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
        data: {
            step: step
        },
        success: function(resp) {
            if (resp.success) {
                $(btn).parent().parent().find('.tab-label').addClass("complete");
                $(btn).prop("disabled", true);
            }
        }
    });
}

function fetchTopProducts() {
    $('#topProducts').DataTable({
        dom: getDom(),
        oLanguage: getLanguage(),
        processing: true,
        serverSide: true,
        pagingType: "numbers",
        ajax: {
            url: "/dashboard/top_products",
            type: "GET",
            data: function(d){
              d.date_range = [$('#dashboardDateRangePicker').data('daterangepicker').startDate.format('YYYY-MM-DD'), $('#dashboardDateRangePicker').data('daterangepicker').endDate.format('YYYY-MM-DD')]
              d.channel = $('#amazonDashboardChannelFilter').val()
            },
        },
        bFilter: false,
        bInfo: false,
        bLengthChange: false,
        paging: false,
        ordering: false,
        createdRow: function(row) {
          $(row).css({"border-bottom": "none"});
        },
        columns: [
            {
              title: "Product",
              data: "name",
              render: function(data, type, row) {
                var html = `<div class="float-left table-img mr-2 br-5">
                                <img alt=" " class="img-fluid" src="${row.image_url}">
                            </div>
                            <div>
                                <div>
                                    <span style="max-width: 30vw; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;">${row.name|| "Missing Listing"}</span>
                                </div>
                                <div>
                                    <span>${row.sku || "Missing Listing"}</span>
                                </div>
                            <div>`
                return html
              }
            },
            {
              title: "Units",
              data: "units_sold"
            },
            {
              title: "Revenue",
              data: "revenue",
              render: function(data, type, row) {
                return toMoney(row.revenue) || "--.--";
              }
            },
            {
              title: "Profit",
              data: "total_profit",
              render: function(data, type, row) {
                var profit = parseFloat(row.revenue) - parseFloat(row.total_fees) - parseFloat(row.total_cost || 0);
                return toMoney(profit) || "--.--";
              }
            }
        ],
    });
}

function fetchDashboardData() {
    if ($('#dashboardDateRangePicker').length == 0) { return false }

    $.ajax({
        url: "/dashboard/analytics",
        type: "GET",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            date_range: [$('#dashboardDateRangePicker').data('daterangepicker').startDate.format('YYYY-MM-DD'), $('#dashboardDateRangePicker').data('daterangepicker').endDate.format('YYYY-MM-DD')],
            channel: $('#amazonDashboardChannelFilter').val()
        },
        success: function(resp) {
            setupDashboardCards(resp.card_analytics);
            setupDashboardChart(resp.analytics)
        }
    });
}

function setupDashboardCards(data){
  var roi_cost = data.cost;
  if (roi_cost == 0) {
    roi_cost = 1;
  }
  $("#dashboardRevenue").text(toMoney(data.revenue || 0))
  $("#dashboardCost").text(toMoney(data.cost || 0))
  $("#dashboardProfit").text(toMoney(data.profit || 0))
  $("#dashboardROI").text(toPercent((data.profit / roi_cost) * 100))
}

function setupDashboardChart(chartData) {
    if (!document.getElementById('dashboardChart')) {return false}
    
    const ctx = document.getElementById('dashboardChart').getContext('2d');
    const labels = chartData.dates
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Revenue',
          data: chartData.revenue,
          borderColor: "#2196f3",
          backgroundColor: "rgb(33, 150, 243, 0.25)",
          tension: 0.5,
          borderWidth: 5,
          pointRadius: 0,
          pointBorderWidth: 1,
          pointHoverBorderWidth: 8,
          pointHoverRadius: 4,
          fill: true
        },
        {
          label: 'Cost',
          data: chartData.cost,
          borderColor: "#ffc107",
          backgroundColor: "rgb(255, 193, 7, 0)",
          tension: 0.5,
          borderWidth: 5,
          pointRadius: 0,
          pointBorderWidth: 1,
          pointHoverBorderWidth: 8,
          pointHoverRadius: 4,
          fill: true
        },
        {
          label: 'Profit',
          data: chartData.profit,
          borderColor: "#009688",
          backgroundColor: "rgb(0, 150, 136, 0)",
          tension: 0.5,
          borderWidth: 5,
          pointRadius: 0,
          pointBorderWidth: 1,
          pointHoverBorderWidth: 8,
          pointHoverRadius: 4,
          fill: true
        }
      ]
    };

    dashboardChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              enabled: true,
              position: 'nearest',
              mode: 'index',
              backgroundColor: 'rgb(51,51,79)',
              xPadding: 8,
              yPadding: 8,
              yAlign: 'bottom',
              bodyAlign: 'right',
              displayColors: false,
              callbacks: {
                label: function (context) {
                  return context.dataset.label + ': ' + toMoney(context.parsed.y);
                }
              }
            }
          },
          interaction: {
            intersect: false,
            axis: 'x',
            mode: 'index',
          },
          scales: {
            y: { 
              grid: { color: "#f7f7f9", borderWidth: 0 },
              ticks: { 
                  maxTicksLimit: 6, 
                  callback: function (value) {
                    var prefix = '$'

                    if (value < 0) {
                        prefix = '- $'
                        value = value * -1
                    }

                    return prefix + formatNumberWithMetricPrefix(value);
                }
              },
              gridLines: {
                display: !1,
                drawBorder: !1
              },
            },
            x: { 
              grid: { display: false, borderWidth: 0},
              ticks: { maxTicksLimit: 12 }
            }
          },
        }
    });
}