import { delayed, flashSuccess, flashError, toMoney, fetchOrdersAnalytics } from 'packs/helper';

$(function() {
    setupWalmartOrdeItemEditListeners();
});

function setupWalmartOrdeItemEditListeners() {
    $(document).on("click", "#editWalmartOrderItemSave", function() {
        $(this).prop('disabled', true);
        saveWalmartOrderItem(this);
    })
}

function saveWalmartOrderItem(button) {
    $.ajax({
        url: "/walmart_order_items/update",
        type: "PATCH",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            order_item_ids: $("#editOrderItemIds").val(),
            status: $("#editStatus").val(),
            source_order_number: $("#editOrderNumber").val(),
            source_cost: $("#editItemCost").val(),
            source_shipping: $("#editShipping").val(),
            source_tax: $("#editSourceTax").val(),
            refunded_to_client: $("#editRefundedToClient").val(),
            refunded_from_supplier: $("#editSupplierRefund").val(),
            manual_adjustment: $("#manualAdjustment").val(),
            notes: $("#editNotes").val()
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
            } else {
                var updatedOrder = resp.order[0]
                var row = $('#orders').DataTable().row($("#order-row-" + updatedOrder.distinct_order_item_id[0]).closest('tr'));
                row.data(updatedOrder)
                fetchOrdersAnalytics();
                
                $("#orderItemEditModal").html("");
                $("#orderItemEditModal").modal("hide");
                flashSuccess(resp.message);
            }

            $(button).prop('disabled', false);
        }
    });
}
