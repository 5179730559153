import { delayed, toMoney } from 'packs/helper';

$(function() {
    setupOrdeItemEditListeners();
});

function setupOrdeItemEditListeners() {
    $(document).on("click", "#orderEditShowMore", function() {
        orderEditShowExtraAttributes() 
    })

    $(document).on("change", "#editAmazonStatus", function() {
        var status = $("#editStatus").val() || $("#editAmazonStatus").val()
        if (status == 'refunded' || status == 'cancelled') {
            orderEditShowExtraAttributes();
        }
    })

    $(document).on("click", "#orderEditShowLess", function() {
        $(this).hide();
        $("#orderEditShowMore").show();
        $("#orderEditExtraFields").hide();
    })

    $(document).on("input", "#editOrderNumber", delayed(function (e) {
        var status = $("#editStatus").val() || $("#editAmazonStatus").val()
        if (status == 'new') {
            $("#editStatus").val('ordered');
            $("#editAmazonStatus").val('ordered');
        }
    }, 300));
}

function orderEditShowExtraAttributes() {
    $("#orderEditShowMore").hide();
    $("#orderEditShowLess").show();
    $("#orderEditExtraFields").show();
}