import { toMoney} from 'packs/helper';
var chromeExtensionId = "eoaeklbkdfgbkbdgelcgfnkaolkkcalj" // Prod
// var chromeExtensionId = "hnbcnnmaojpkfmeoegapdmhaeeidnmkd" // Dev

$(document).on("click", '.place-order-btn', function (e) {
    var sourceUrl = $(this).data('sourceurl');
    var orderUrl = $(this).data('orderurl');
    var is_gift_mode = $("#orderModalGiftMode").val() == "true"

    if (is_gift_mode) {
        sourceUrl = sourceUrl + '?listRegistryId=9788645b-854e-4e3a-a6a3-949e3054b925&listRegistryType=WR'
    }

    if ($("#orderModalEnabled").val() == "false") {
        if (orderUrl) window.open(orderUrl, '_blank');
        if (sourceUrl) window.open(sourceUrl, '_blank');
        return False;
    }

    try {

        // Tell the extension to open the modal on the vendor site
        chrome.runtime.sendMessage(chromeExtensionId, {message: "status"}, function (response) { 
            if (chrome.runtime.lastError) {
                if (orderUrl) window.open(orderUrl, '_blank');
                if (sourceUrl) window.open(sourceUrl, '_blank');
            }
        });

        var row = $('#amazonOrders').DataTable().row($(this).parents('tr'));
        var rowData = row.data();

        var data = { orderUrl: orderUrl,
                     sourceUrl: sourceUrl,
                     cost: rowData.source_cost,
                     sourcePrice: rowData.current_source_price,
                     net_payable: rowData.net_payable,
                     quantity: rowData.quantity,
                     sourceShipping: (rowData.source_shipping || 0),
                     sourceTax: (rowData.source_tax || 0),
                     sourceOrderId: rowData.source_order_id,
                     source_order_number: rowData.source_order_number,
                     orderItemId: rowData.order_item_id,
                     city: rowData.city,
                     state: rowData.state,
                     phone_number: rowData.phone_number,
                     shipping_address_name: rowData.address_name,
                     shipping_address_line1: rowData.address_1,
                     shipping_address_line2: rowData.address_2,
                     postalCode: rowData.postal_code };
    
        var messageData = { message: "placeOrder", order: data, pos_x: "70%", pos_y: "10%" }
    
        // Tell the extension to open the modal on the vendor site
        chrome.runtime.sendMessage(chromeExtensionId, messageData, function (response) { 
            if (chrome.runtime.lastError) {
                console.log(chrome.runtime.lastError);
            }
        });
    } catch(err) {
        if (orderUrl) window.open(orderUrl, '_blank');
        if (sourceUrl) window.open(sourceUrl, '_blank');
    }
});

export const getSkuElement = function(sku, type = 'walmart') {
    var iconUrl = 'https://ecommerce-io.s3.amazonaws.com/walmart-icon.svg';
    var sourceUrl = '/listings?search=' + sku;
    
    if (type == 'amazon') {
        iconUrl = 'https://ecommerce-io.s3.amazonaws.com/amazon-icon.svg';
        sourceUrl = 'https://amazon.com/dp/' + sku;
    }

    if (sku) {
        return `<span class="text-muted">Listing: </span> <a class="bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Go to SKU (new tab)" target="_blank" href="${sourceUrl}"><svg style="vertical-align: text-top;" width="17" height="17"><image xlink:href="${iconUrl}" width="17" height="17"/></svg> ${sku}</a>
                <svg data-text="${sku}" class="action-btn action-btn-sm mr-4 copy-data-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Copy SKU" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                <svg data-text="${sku}" class="action-btn action-btn-sm mr-4 copy-btn-success bs-tooltip" style="display:none;" data-placement="top" data-html="true" data-toggle="tooltip" title="Copy SKU" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>`
    } else {
        return `<span target="_blank" class="mr-4">Listing: <svg style="vertical-align: text-top;" width="17" height="17"><image xlink:href="${iconUrl}" width="17" height="16"/></svg><span class="badge badge-danger">Missing Listing</span></span>`
    }
}

export const getSourcesElement = function(sourceId, sourceUrl, type, is_fba = false) {
    var iconUrl = 'https://ecommerce-io.s3.amazonaws.com/walmart-icon.svg'
    if (type == 'amazon') iconUrl = 'https://ecommerce-io.s3.amazonaws.com/amazon-icon.svg'

    if (is_fba) {
        return `<span class="text-muted">Supplier: </span> <svg style="vertical-align: text-top;" width="16" height="16"><image xlink:href="https://ecommerce-io.s3.amazonaws.com/amazon-icon.svg" width="16" height="16"/></svg> <span class="mr-4">FBA</span>`;
    }

    if (sourceId) {
        return `<span class="text-muted">Supplier: </span> <a class="bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Go to Source (new tab)" target="_blank" href="${sourceUrl}" class="mr-3"><svg style="vertical-align: text-top;" width="16" height="16"><image xlink:href="${iconUrl}" width="16" height="16"/></svg> ${sourceId}</a>
                <svg data-text="${sourceId}" class="action-btn action-btn-sm mr-4 copy-data-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Copy Source Id" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                <svg data-text="${sourceId}" class="action-btn action-btn-sm mr-4 copy-btn-success bs-tooltip" style="display:none;" data-placement="top" data-html="true" data-toggle="tooltip" title="Copy Source Id" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>`
    } else {
        return `<span target="_blank" class="mr-4"><span class="text-muted">Supplier: </span> 
                    Missing Supplier
                    <svg class="action-btn action-btn-sm mr-0 action-btn-delete" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                </span>`
    }
}

export const encodeOrderItemsIds = function(orderItemIds) {
    var baseStr = '';

    orderItemIds.forEach(item_id => {
        baseStr = baseStr + 'order_item_ids[]=' + item_id + '&';
    });

    return baseStr;
}

export const getOrderTableEditButton = function(status, order_item_ids, sourceUrl, type, orderUrl = null, is_fba = false) {
    var urlprefix = '';
    var modalName = 'orderItemEditModal';

    if (is_fba) return '';
    if (type == 'amazon' && sourceUrl == 'https://walmart.com/ip/') return '';

    if (type == 'amazon') {
        modalName = 'amazonOrderItemEditModal';
        urlprefix = 'order_item_ids='
    }

    if (status != 'new') {
        return `<a local="false" data-toggle="modal" data-target="#${modalName}" class="btn btn-sm btn-light-gray edit-order-btn" data-remote="true" href="/${type}_order_items/edit?${urlprefix + order_item_ids}">
                    Edit
                </a>`
    } else {
        return `<a local="false" data-toggle="modal" data-target="#${modalName}" data-sourceurl=${sourceUrl} data-orderurl=${orderUrl} class="mr-1 btn btn-sm btn-primary place-order-btn edit-order-btn" data-remote="true" href="/${type}_order_items/edit?${urlprefix + order_item_ids}">
                    Place Order
                </a>
                <a local="false" data-toggle="modal" data-target="#${modalName}" class="btn btn-sm btn-light-gray edit-order-btn" data-remote="true" href="/${type}_order_items/edit?${urlprefix +order_item_ids}">
                    Edit
                </a>`
    }
}

export const getOrdersProfitToolTip = function(total, tax, marketplace_fees, net_payable, source_cost, is_estimate, profit, margin) {
    var estimateStr = ''
    if (is_estimate) estimateStr = ' (est)'

    var html = `<div class='text-right'>
                    <span>Gross Sales: ${toMoney(total)}</span><br>
                    <span>Tax: ${toMoney(tax || 0)}</span><br>
                    <span>Marketplace Fees: ${toMoney(marketplace_fees)}</span><br>
                    <span>Net Payable: ${toMoney(net_payable || 0)}</span><br>
                    <span>Supplier Total${estimateStr}: ${toMoney(source_cost)}</span><br>
                    <span>Profit${estimateStr}: ${toMoney(profit)}</span><br>
                    <span>Margin${estimateStr}: ${margin}%</span><br>                    
                </div>`

    return html;
}

export const getSupplierOrderIdSpan = function(sourceOrderNumber) {
    if (!sourceOrderNumber) return "";

    var html = `${sourceOrderNumber} 
                <svg data-text="${sourceOrderNumber}" class="action-btn action-btn-sm mr-4 copy-data-btn bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="Copy Order Id" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                <svg data-text="${sourceOrderNumber}" class="action-btn action-btn-sm mr-4 copy-btn-success bs-tooltip" style="display:none;" data-placement="top" data-html="true" data-toggle="tooltip" title="Copy Order Id" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>`
    return html;
}

export const getOrderSupplierOrderIdIcon = function(order_item_ids, source_order_number, type) {
    var urlprefix = '';
    var modalName = '#orderItemEditModal'
    
    if (source_order_number) return '';
    if (type == 'amazon') {
      urlprefix = 'order_item_ids=';
      modalName = '#amazonOrderItemEditModal';
    }

    var html = `<a local="false" data-toggle="modal" data-target="${modalName}" class="btn btn-icon edit-order-btn" data-remote="true" href="/${type}_order_items/edit?${urlprefix + order_item_ids}">
                    <svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                </a>`

    return html
}