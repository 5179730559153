import { flashSuccess, flashError } from 'packs/helper';

$(function() {
    setupListingEditListeners();
});

function setupListingEditListeners() {
    $(document).on("click", "#editListingSave", function() {
        $(this).prop('disabled', true);
        saveListing(this);
    })
}

function saveListing(button) {
    var listingId = $("#editListingListingId").val();

    $.ajax({
        url: "/listings/" + listingId,
        type: "PATCH",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            source_url: $("#editListingSourceURL").val(),
            repricer_strategy_id: $("#editListingsRepricerStrategy").val(),
            price: $("#editListingPrice").val(),
            lag_time: $("#editListingLagTime").val(),
            purchase_quantity: $("#editListingPurchaseQuantity").val(),
            min_price: $("#editListingMinPrice").val(),
            disable_repricer: $("#listingDisableRepricer").val(),
            disable_stock_monitoring: $("#listingDisableStockMonitoring").val()
        },
        success: function(resp) {
            if (resp.success == false) {
                flashError(resp.message);
            } else {
                flashSuccess(resp.message);
                $("#editListingModal").html("");
                $("#editListingModal").modal("hide");
                $('#listings').DataTable().ajax.reload(null, false);

                $("#editAmazonListingModal").html("");
                $("#editAmazonListingModal").modal("hide");
                $('#amazonListings').DataTable().ajax.reload(null, false);
            }

            $(button).prop('disabled', false);
        }
    });
}