require('datatables.net-bs4')

import { getDom, getLanguage, flashError, toMoney } from 'packs/helper';

$(function() {
  setupOgranizationsUsersTable();
  setupOrganizationUsersListeners();
});

function setupOrganizationUsersListeners() {
  $(document).on('ajax:success', '.sku-cost-form', function(e, data, status, xhr) {
      $("#addSkuCostModal").modal("hide");
      $('.modal-backdrop').remove();
      $('#skuCosts').DataTable().ajax.reload();
  }).on('ajax:error', '.sku-cost-form', function(e){
      var detail = e.detail;
      var error = detail[0];
      flashError(error["errors"]);
  });
}

function setupOgranizationsUsersTable() {
  $('#skuCosts').DataTable({
      dom: getDom(),
      oLanguage: getLanguage(),
      processing: true,
      serverSide: true,
      pagingType: "numbers",
      ajax: {
          url: "/sku_cost",
          type: "GET"
      },
      bFilter: false,
      bLengthChange: false,
      paging: true,
      pageLength: 20,
      ordering: false,
      columns: [
          {
            title: "Product",
            data: "name",
            render: function(data, type, row) {
              var html = `<div class="float-left table-img mr-2 br-5">
                              <img alt=" " class="img-fluid" src="${row.image_url}">
                          </div>
                          <div style="line-height: 40px;">
                              <span>${row.name || "Missing Listing"}</span>
                          <div>`
              return html
            }
          },
          {
            title: "SKU",
            data: "sku"
          },
          {
            title: "Cost",
            data: "cost",
            render: function(data, type, row) {
              return toMoney(row.cost) || "--.--";
            }
          },
          {
              title: "",
              width: "30px",
              class: "text-right",
              render: function(data, type, row){
                  var html = '';
                  if (row.role != 'owner') {
                    html = `<button class="btn btn-icon delete-btn" data-table="skuCosts" data-path="/sku_cost/" data-id="${row.id}"><svg class="action-btn" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button>`
                  }

                  return html;
              }
          }
      ],
  });
}