require('datatables.net-bs4')
import { flashError, toMoney, getDom, getLanguage, readableStatus, trimChars } from 'packs/helper';
import { listingSourceStatusBadge } from 'packs/shared/listings';

$(function() {
    $(document).on("click", ".listing-history", function() {
        var listingId = $(this).data('id');
        var row = $('#amazonListings').DataTable().row($(this).parents('tr'));
        var data = row.data();

        $("#listingHistoryImage").attr('src', data.image_url);
        $("#listingHistoryLink").attr('href', `https://amazon.com/dp/${data.sku}`);
        $("#listingHistoryLink").html(`${trimChars(data.name || '--', 25)}<svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>`)
        $("#listingHistorySku").text(data.sku)
        $("#listingHistoryPrice").text(toMoney(data.price))
        $("#listingHistoryStatus").append(`<span>${makeAmazonBadge(readableStatus(data.status), data.in_stock, data.error_message)}</span>`)
        
        $("#vendorListingHistoryImage").attr('src', data.source_image_url);
        $("#vendorListingHistoryLink").attr('href', data.source_url);
        $("#vendorListingHistoryLink").html(`${trimChars(data.source_name || '--', 25)}<svg class="action-btn action-btn-sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>`)
        $("#vendorListingHistorySku").text(data.source_id)
        $("#vendorListingHistoryPrice").text(toMoney(data.cost))
        $("#vendorListingHistoryStatus").append(`<span>${listingSourceStatusBadge(data.source_in_stock, data.is_valid)}</span>`)
        
        setupListingHistory(listingId);
    })

    $('#viewListingHistory').on('hidden.bs.modal', function () {
        $("#listingHistory").DataTable().destroy();
        $("#vendorListingHistory").DataTable().destroy();
        $("#listingHistoryImage").attr('src', '');
        $("#listingHistoryLink").attr('href', '');
        $("#listingHistoryLink").html('')
        $("#listingHistorySku").text('')
        $("#listingHistoryPrice").text('')
        $("#listingHistoryStatus").empty()
        
        $("#vendorListingHistoryImage").attr('src', '');
        $("#vendorListingHistoryLink").attr('href', '');
        $("#vendorListingHistoryLink").html('')
        $("#vendorListingHistorySku").text('')
        $("#vendorListingHistoryPrice").text('')
        $("#vendorListingHistoryStatus").empty()
    })
});

function setupListingHistory(listingId) {
    setupListingHistoryTable(listingId);
    setupVendorListingHistoryTable(listingId);
}

function setupListingHistoryTable(listingId) {
    $('#vendorListingHistory').DataTable({
        dom: getDom(),
        oLanguage: getLanguage(),
        processing: true,
        serverSide: true,
        pagingType: "numbers",
        ajax: {
            url: "/listings/vendor_history",
            type: "GET",
            data: {
                id: listingId
            }
        },
        bFilter: false,
        bLengthChange: false,
        paging: true,
        pageLength: 5,
        ordering: false,
        columns: [
            {
              title: "Changed",
              data: "changed_at"
            },
            {
              title: "Price",
              data: "buy_box_price",
              render: function(data, type, row) {
                return toMoney(row.buy_box_price) || "--.--";
              }
            },
            {
                title: "Shipping",
                data: "shipping_fee",
                render: function(data, type, row) {
                  return toMoney(row.shipping_fee) || "--.--";
                }
            },
            {
                title: "In Stock",
                data: "in_stock",
                render: function(data, type, row) {
                    if (row.in_stock) {
                        return '<span class="badge badge-success">In Stock</span>';
                    } else {
                        return '<span class="badge badge-warning">Out Of Stock</span>';
                    }
                }
            },
        ],
    });
}

function setupVendorListingHistoryTable(listingId) {
    $('#listingHistory').DataTable({
        dom: getDom(),
        oLanguage: getLanguage(),
        processing: true,
        serverSide: true,
        pagingType: "numbers",
        ajax: {
            url: "/listings/history",
            type: "GET",
            data: {
                id: listingId
            }
        },
        bFilter: false,
        bLengthChange: false,
        paging: true,
        pageLength: 5,
        ordering: false,
        columns: [
            {
              title: "Changed",
              data: "changed_at"
            },
            {
              title: "Price",
              data: "price",
              render: function(data, type, row) {
                return toMoney(row.price) || "--.--";
              }
            },
            {
                title: "Stock",
                data: "in_stock",
                render: function(data, type, row) {
                    if (row.in_stock) {
                        return '<span class="badge badge-success">In Stock</span>';
                    } else {
                        return '<span class="badge badge-warning">Out Of Stock</span>';
                    }
                }
            },
            {
                title: "Handling",
                data: "handling_time"
            },
            {
                title: "Stock Monitor",
                data: "disable_stock_monitoring",
                render: function(data, type, row) {
                    var enabled = true
                    if (row.disable_stock_monitoring) enabled = false;

                    if (enabled) {
                        return '<span class="badge badge-success">Enabled</span>';
                    } else {
                        return '<span class="badge badge-secondary">Disabled</span>';
                    }
                }
            },
            {
                title: "Repricer",
                data: "disable_repricer",
                render: function(data, type, row) {
                    var enabled = true
                    if (row.disable_repricer) enabled = false;

                    if (enabled) {
                        return '<span class="badge badge-success">Enabled</span>';
                    } else {
                        return '<span class="badge badge-secondary">Disabled</span>';
                    }
                }
            },
        ],
    });
}

function makeAmazonBadge(status, in_stock, error_message) {
    if (status == 'Active') {
        if (in_stock) {
            return '<span class="badge badge-success">In Stock</span>';
        } else {
            return '<span class="badge badge-warning">Out Of Stock</span>';
        }
    } else if (status == 'Pending Removal') {
        return '<span class="badge badge-warning">' + status + '</span>';
    } else if (status == 'Error' && error_message && error_message != "") {
        return '<span class="badge badge-dark bs-tooltip" data-placement="top" data-html="true" data-toggle="tooltip" title="' + error_message + '">' + status + '</span>';
    } else {
        return '<span class="badge badge-dark">' + status + '</span>';
    }
}