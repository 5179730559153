import { toMoney, flashSuccess, flashError } from 'packs/helper';

$(function() {
    $(document).on('ajax:success', '.global-settings-form', function(e, data, status, xhr) {
        $("#globalListingSettingsModal").modal("hide");
        $('.modal-backdrop').remove();
        $('#listings').DataTable().ajax.reload();
        $('#amazonListings').DataTable().ajax.reload();
    }).on('ajax:error', '.global-settings-form', function(e){
        var detail = e.detail;
        var error = detail[0];
        flashError(error["errors"]);
    });

    $("#toggleFilters").on('click', function () {    
        $("#filtersDiv").toggle(100);
    })
})

export const updateListingStrategy = function(listingId, strategyId) {
  $.ajax({
      url: "/listings/" + listingId,
      type: "PATCH",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: {
          repricer_strategy_id: strategyId
      },
      success: function(resp) {
          if (resp.success == false) {
              flashError(resp.message);
          } else {
              flashSuccess("Strategy succesfully changed!");
          }
      }
  });
}

export const getListingsProfitToolTip = function(revenue, cost, tax, fees) {
  var html = `<div class='text-right'>
                  <span>Revenue: ${toMoney(revenue)}</span><br>
                  <span>Cost: ${toMoney((cost || 0), 2)}</span><br>
                  <span>Tax: ${toMoney((tax || 0), 2)}</span><br>
                  <span>Fees: ${toMoney(fees, 2)}</span><br>
              </div>`

  return html;
}

export const getListingsStrategyToolTip = function(min_price, max_price) {
  var html = `<div class='text-right'>
                  <span>Min Price: ${toMoney(min_price)}</span><br>
                  <span>Max Price: ${toMoney(max_price)}</span><br>
              </div>`

  return html;
}

export const getDatatableStrategiesHtml = function(selectedId) {
  var strategies = JSON.parse($("#listingRepricerStrategies").val());
  var html = '<option value="">Store Strategy</option>';

  strategies.forEach(strategy => {
      var selectedHtml = ""
      if (selectedId == strategy.id) selectedHtml = 'selected';

      html += `<option ${selectedHtml} value="${strategy.id}">${strategy.name}</option>`;
  });

  return html;
}

export const getImageDiv = function(url) {
  if (url) {
      return `<div class="mr-2 br-5 table-img-lg float-left">
                  <img alt="" class="img-fluid" src="${url}">
              </div>`
  } else {
      return '<div class="mr-2 br-5 table-img-lg float-left"></div>'
  }
}

export const listingSourceStatusBadge = function(in_stock, is_valid) {
  if (!is_valid) {
      return '<span class="badge badge-danger">Invalid Supplier</span>';
  } else if (in_stock == false) {
      return '<span class="badge badge-warning">Out of Stock</span>';
  } else if (in_stock == true) {
      return '<span class="badge badge-success">In Stock</span>';
  } else {
      return "";
  }
}

export const removeListing = function(listingId) {
  $.ajax({
      url: "/listings/" + listingId,
      type: "PATCH",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: {
          status: "pending_removal"
      },
      success: function(resp) {
          if (resp.success == false) {
              flashError(resp.message);
          } else {
              flashSuccess(resp.message);
              $('#amazonListings').DataTable().ajax.reload(null, false);
              $('#listings').DataTable().ajax.reload(null, false);
          }
      }
  });
}