import { delayed, toMoney } from 'packs/helper';

$(function() {
    setupListingsNewListeners();
    $("#walmartUrl").trigger('focus');
});

function setupListingsNewListeners() {
    $(document).on("input", "#walmartUrl", delayed(function (e) {
        clearErrorMessages();
        clearWalmartProductInfo();
        walmartUrlChanged($(this).val());
    }, 300));

    $(document).on("input", "#amazonUrl", delayed(function (e) {
        clearErrorMessages();
        clearAmazonProductInfo();
        amazonUrlChanged($(this).val());
    }, 300));

    $(document).on("click", "#clearCreateListing", function (e) {
        resetListingCreateView();
    });
}

function walmartUrlChanged(url) {
    $("#walmartLoader").show();
    $("#createListing").prop('disabled', true);

    $.ajax({
        url: "get_walmart_data",
        type: "GET",
        data: {
          url: url
        },
        success: function(resp) {
            if (resp.success == false) {
                $("#walmartFeedback").html(resp.message);
                $("#walmartFeedback").show();
            } else {
                $("#amazonUrl").trigger('focus');
                $("#walmartProductTitle").text(resp.data.name);
                $("#walmartProductPrice").text('$' + parseFloat(resp.data.price).toFixed(2));
                $("#walmartProductStock").text(resp.data.in_stock);
                if (resp.data.image_url) $("#walmartProductImg").attr('src', resp.data.image_url);
                $("#wpTitle").val(resp.data.name);
                $("#wpPrice").val(parseFloat(resp.data.price).toFixed(2));
                $("#wpCategoryId").val(resp.data.category_id);
                $("#wpSellingQty").val('1');
                $("#wpStock").val(resp.data.in_stock);
                $("#wpImage").val(resp.data.image_url);
                $("#wpUPC").val(resp.data.upc);
                $("#walmartExtras").show();
            }
            $("#walmartLoader").hide();
            $("#createListing").prop('disabled', false);
        }
    });
}

function amazonUrlChanged(url) {
    $("#amazonLoader").show();
    $("#createListing").prop('disabled', true);

    $.ajax({
        url: "get_amazon_data",
        type: "GET",
        data: {
          url: url
        },
        success: function(resp) {
            if (resp.success == false) {
                $("#amazonFeedback").html(resp.message);
                $("#amazonFeedback").show();
            } else {
                $("#amazonProductTitle").text(resp.data.title);
                $("#amazonProductStock").text(resp.data.in_stock);
                
                if (resp.data.image_url) {
                    $("#amazonProductImg").attr('src', resp.data.image_url);
                }

                if (resp.data.price) {
                    $("#amazonProductPrice").text('$' + parseFloat(resp.data.price).toFixed(2));
                    $("#apPrice").val(parseFloat(resp.data.price).toFixed(2));
                } else {
                    $("#amazonProductPrice").text('Unknown');
                }

                $("#amazonExtras").show();
            }

            $("#amazonLoader").hide();
            $("#createListing").prop('disabled', false);
        }
    });
}

function clearErrorMessages(){
    $("#amazonFeedback").html("");
    $("#amazonFeedback").hide();
    $("#walmartFeedback").html("");
    $("#walmartFeedback").hide();
}

function clearWalmartProductInfo(){
    $("#walmartProductTitle").text("");
    $("#walmartProductPrice").text("");
    $("#walmartProductStock").text("");
    $("#walmartProductImg").attr('src', 'https://s3.amazonaws.com/ecomexplorer/image-icon.png');
    $("#wpPurchaseQty").val('1');
    $("#wpTitle").val('');
    $("#wpPrice").val('');
    $("#wpCategoryId").val('');
    $("#wpStock").val('');
    $("#wpImage").val('');
}

function clearAmazonProductInfo(){
    $("#apPrice").val('');
    $("#apHandlingTime").val('3');
    $("#apPurchaseQty").val('1');
    $("#amazonProductTitle").text("");
    $("#amazonProductPrice").text("");
    $("#amazonProductStock").text("");
    $("#amazonProductImg").attr('src', 'https://s3.amazonaws.com/ecomexplorer/image-icon.png');
}

function resetListingCreateView() {
    clearErrorMessages();
    clearAmazonProductInfo();
    clearWalmartProductInfo();
    $("#walmartExtras").hide();
    $("#amazonExtras").hide();
    $("#walmartUrl").val("");
    $("#amazonUrl").val("");
    $("#walmartUrl").trigger('focus');
}